// Follow issue ==> https://github.com/react-bootstrap/react-bootstrap/issues/5075

import React from 'react';
import i18n from '../../i18n';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function AlertModalComponent(props) {
    const [open] = React.useState(true);


    const handleClose = () => {
        props.cancel(false)
    };

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <span><Icon style={{ color: "red", fontSize: "1.5em", margin: "0 10px 0 0" }}>error</Icon></span>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {props.infoMessage}
                    </Typography>
                    <Typography gutterBottom>
                        {props.warningMessage}
                    </Typography>
                    <Typography style={{ textAlign: "center" }} gutterBottom>
                        {props && props.userToDelete && (
                            <strong>{props.userToDelete.fullName}</strong>
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={() => props.handleConfirm(props.item._id)} color="secondary">
                        {i18n.t('CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}