import ApiFetch from '../../../services/api-fetch';

const Resource = function () {

    this.apiFetch = new ApiFetch();

    this.getShirtsPerTeam = async function (url, filters) {

        const date = new Date(filters).toISOString();

        return await this.apiFetch.get(`${url}?createdAt=${date}`)
    };

    this.getShirtsPerCountry = async function (url, filters) {

        const date = new Date(filters).toISOString();

        return await this.apiFetch.get(`${url}?createdAt=${date}`);
    };

    this.getShirtsPerState = async function (url, filters) {
        const date = new Date(filters).toISOString();

        return await this.apiFetch.get(`${url}?createdAt=${date}`)
    };

    this.getShirtsPerMusuem = async function (url, filters) {
        return await this.apiFetch.get(`${url}`);
    }

    this.getUsersPerDevice = async function (url, filters) {
        return await this.apiFetch.get(`${url}`)
    }
};

export default Resource;