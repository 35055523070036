import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect'

import i18n from '../../i18n'

import ApiFetch from '../../services/api-fetch'

import getRelativePath from '../../helpers/getRelativePath'

import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';


import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';



const useStyles = makeStyles((theme) => ({
    root: {
        margin: "20px 0 0 50px",
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));


export default function CountryDetailView(props) {
    const classes = useStyles();

    const [data, setData] = useState({});
    const [name, setName] = useState('');
    const [editStatus, setEditStatus] = React.useState(false);

    const apiFetch = new ApiFetch()

    async function fetchApi() {
        const relativePath = getRelativePath(props.location.pathname)
        const url = `countries/${relativePath}`
        const response = await apiFetch.get(url)
        if (!response) {
            setData({})
        } else {
            setData(response)
        }
    }

    const updateName = () => {

    }

    useDeepCompareEffect(() => {
        fetchApi()
    }, [data]);

    return (
        <Grid container spacing={12}>
            <Grid item xs={12} sm={6}>

                <List className={classes.root}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <RoomIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={i18n.t('NAME')} secondary={data.country ? data.country.name.es : ""} />
                        <IconButton onClick={() => setEditStatus(true)} aria-label="delete" label="asdasd">
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={6}>
                {editStatus && (
                    <FormControl variant="filled" className={classes.input}>
                        <TextField
                            value={name}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            placeholder={i18n.t('NAME')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Button color="primary" onClick={updateName}>Confirmar</Button>
                    </FormControl>
                )}
            </Grid>

        </Grid>
    );
}
