import React from "react";

import { Route } from 'react-router-dom';

import { getJwt } from '../helpers/get-jwt';
import { getUserJwt } from '../helpers/get-user-jwt';
import { RoleType } from '../types/UserTypes';

import i18n from '../i18n';

import AuthComponent from '../components/auth/AuthComponent';

import HomeComponent from '../components/home/HomeComponent';
import ViewNewComponent from '../components/home/ViewNewComponent';
import ActivityComponent from '../components/home/ActivityComponent';

import ShirtIndexComponent from '../components/shirt/ShirtIndexComponent';
import ShirtDetailViewComponent from '../components/shirt/ShirtDetailViewComponent';

import UserIndexComponent from '../components/user/UserIndexComponent';
import UserDetailViewComponent from '../components/user/UserDetailViewComponent';

import TeamDetailViewComponent from "./team/TeamDetailViewComponent";
import TeamIndexComponent from '../components/team/TeamIndexComponent';
import TeamAddComponent from '../components/team/TeamAddComponent';

import CountryIndexComponent from '../components/country/CountryIndexComponent';
import CountryDetailViewComponent from '../components/country/CountryDetailViewComponent';
import CountryAddComponent from "./country/CountryAddComponent";

import CommentIndexComponent from '../components/comment/CommentIndexComponent'

import VinimayTeamIndexComponent from "./vinimay-team/VinimayTeamIndexComponent";
import VinimayTeamEditComponent from "./vinimay-team/VinimayTeamEditComponent";
import VinimayTeamAddComponent from "./vinimay-team/VinimayTeamAddComponent";

import StatsComponent from '../components/stats/StatsComponent';

import ImageIndexComponent from './image/ImageIndexComponent';

import SideBar from '../components/sidebar/SideBar';

import ChangelogComponent from '../components/ChangelogComponent';

import MainHeaderComponent from '../components/MainHeaderComponent';


function WrapperComponent(props) {

    const [user, setUser] = React.useState({})

    async function fetchAuthUser() {
        const jwt = getJwt()

        if (!jwt) {
            return props.history.push('/login')
        }

        const user = getUserJwt(jwt)

        if (user.role !== RoleType.ADMIN && user.role !== RoleType.EMPLOYEE) {
            localStorage.removeItem('jwt-token')
            return props.history.push('/login')
        }
        setUser(user)
    }

    const loadRoutes = () => {
        const protectedIndex = [8, 9, 12, 15, 16, 17]

        const routes = [
            { id: 1, path: '/', component: HomeComponent },
            { id: 2, path: '/users', component: UserIndexComponent },
            { id: 3, path: '/users/view/:id', component: UserDetailViewComponent },
            { id: 4, path: '/shirts', component: ShirtIndexComponent },
            { id: 5, path: '/shirts/view/:id', component: ShirtDetailViewComponent },
            { id: 6, path: '/teams', component: TeamIndexComponent },
            { id: 7, path: '/teams/view/:id', component: TeamDetailViewComponent },
            { id: 8, path: '/teams/add', component: TeamAddComponent },
            { id: 9, path: '/countries', component: CountryIndexComponent },
            { id: 10, path: '/countries/view/:id', component: CountryDetailViewComponent },
            { id: 11, path: '/countries/add', component: CountryAddComponent },
            { id: 12, path: '/comments', component: CommentIndexComponent },
            { id: 13, path: '/images', component: ImageIndexComponent },
            { id: 14, path: '/vinimay-team', component: VinimayTeamIndexComponent },
            { id: 15, path: '/vinimay-team/edit/:id', component: VinimayTeamEditComponent },
            { id: 16, path: '/vinimay-team/add', component: VinimayTeamAddComponent },
            { id: 17, path: '/new', component: ViewNewComponent },
            { id: 18, path: '/activity', component: ActivityComponent },
            { id: 29, path: '/stats', component: StatsComponent },
            { id: 20, path: '/changelog', component: ChangelogComponent },
        ];

        const publicRoutes = routes.filter(route => !protectedIndex.includes(route.id));

        const _routes = user.role === RoleType.ADMIN ? routes : publicRoutes;

        return _routes.map((route) => {
            return <Route key={route.id} exact path={route.path} component={route.component} />

        })
    }

    React.useEffect(() => {
        fetchAuthUser()
    }, []);

    return (
        <div>
            <AuthComponent loggedInUser={user}>
                <SideBar user={user}>
                    <div>
                        <MainHeaderComponent data={i18n.t('WELCOME')} />
                    </div>
                    {loadRoutes()}
                </SideBar>
            </AuthComponent>
        </div>
    )
}

export default WrapperComponent;

