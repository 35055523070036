import i18n from '../../../i18n';

const columns = [
    {
        Header: i18n.t('USERNMAE'),
        accessor: 'username',
        filterable: false,
    },
    {
        Header: i18n.t('EMAIL'),
        accessor: 'email',
        filterable: false,
    },
    {
        Header: i18n.t('TOTAL'),
        accessor: 'totalShirts',
        filterable: false,
    },
]

export default columns;