import React from "react";
import { useTable, useFilters } from "react-table";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useLocation } from "react-router-dom";

import TableOptionsComponent from "../TableOptionsComponent";
import SnackBarComponent from "../SnackBarComponent";
import SpinnerComponent from "../SpinnerComponent";

import i18n from "../../i18n";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    minWidth: 400,
    maxWidth: 1000,
  },
  options: {
    marginBottom: "1%",
  },
  title: {
    fontFamily: "Roboto Condensed",
    color: "#36425B",
    textDecoration: "underline",
  },
  table: {
    fontSize: "0.9em",
    backgroundColor: "#f6f5f5",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  footer: {
    position: "absolute",
    marginTop: "20px",
    marginBottom: "20px",
  },
}));

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <TextField
      d="outlined-search"
      type="search"
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

function SelectColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <Select
      style={{ width: "80px" }}
      size={"small"}
      onChange={(e) => {
        setFilter(e.target.value);
      }}
      value={filterValue ? filterValue : undefined}
    >
      <option value={"Adidas" || ""}>Adidas</option>
      <option value={"Nike" || ""}>Nike</option>
      <option value={"Puma" || ""}>Puma</option>
      <option value={"Topper" || ""}>Topper</option>
      <option value={"Umbro" || ""}>Umbro</option>
      <option value={"Kappa" || ""}>Kappa</option>
      <option value={"" || ""}>All</option>
    </Select>
  );
}

function Table({
  columns,
  data,
  filters,
  setFilters,
  pageNum,
  pageSize,
  total,
  setPagination,
  sendSnackBar,
  snackBarMessage,
  setSendSnackBackToFalse,
  path,
  addButton,
  opts,
  resourceName,
  filterSelectOption,
}) {
  const classes = useStyles();

  const location = useLocation();

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      SelectFilter: SelectColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      manualFilters: true,
      manualPagination: true,
      pageNum,
      initialState: {
        filters,
        pageNum,
      },
    },
    useFilters
  );

  const handlePageNum = (event, page) => {
    setPagination(page);
  };

  const handleResetFilters = () => {
    setFilters([]);
  };

  const _setSendSnackBarToFalse = () => {
    setSendSnackBackToFalse();
  };

  useDeepCompareEffect(() => {
    setFilters(state.filters);
  }, [state, setFilters]);

  return (
    <div className={classes.root}>
      {data && data.length === 0 ? (
        <SpinnerComponent />
      ) : (
        <div>
          {sendSnackBar ||
            (location.state && location.state.snackBar && (
              <SnackBarComponent
                snackBarMessage={
                  snackBarMessage || location.state.snackBarMessage
                }
                severity={"success"}
                _setSendSnackBarToFalse={_setSendSnackBarToFalse}
              />
            ))}

          <div className={classes.options}>
            <h1 className={classes.title}>{resourceName}</h1>
            <TableOptionsComponent
              total={total}
              handleResetFilters={handleResetFilters}
              path={`${path}/add`}
              addButton={addButton}
              filterSelectOption={filterSelectOption}
              opts={opts}
            />
          </div>

          <MaUTable className={classes.table} {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, idx) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps({ style: { width: "120px" } })}
                    >
                      {column.render("Header")}
                      <div>
                        {column.filterable &&
                        column.canFilter &&
                        column.Header !== "Brand"
                          ? column.render("Filter")
                          : column.filterable &&
                            column.canFilter &&
                            column.Header === "Brand"
                          ? column.render("SelectFilter")
                          : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell, idx) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "0.85em",
                            padding: "0.1px",
                            color: cell.row.original.isVerified
                              ? "green"
                              : cell.row.original.isSoftDeleted && "red",
                            fontWeight: cell.row.original.isVerified
                              ? "bold"
                              : "initial",
                          }}
                          align="center"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            <Pagination
              className={classes.footer}
              count={Math.ceil(total.total / pageSize)}
              onChange={(event, page) => handlePageNum(event, page)}
            />
          </MaUTable>
        </div>
      )}
    </div>
  );
}

export default Table;
