import React, { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import moment from "moment";

import urls from "./resources/urls";
import Resource from "./resources/Resource";

import ImageShowComponent from "../image/ImageShowComponent";
import DropDownButton from "../DropDownButton";
import AlertModalComponent from "../modals/AlertModalComponent";
import ShowCommentComponent from "../comment/ShowCommentComponent";

import i18n from "../../i18n";

import getRelativePath from "../../helpers/getRelativePath";

import Grid from "@material-ui/core/Grid";

import useStyles from "./styles/useStyles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PeopleIcon from "@material-ui/icons/People";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DescriptionIcon from "@material-ui/icons/Description";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import BlockIcon from "@material-ui/icons/Block";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";

export default function ShirtDetailViewComponent(props) {
  const classes = useStyles();

  const resource = new Resource();

  const [data, setData] = useState({});
  const [showImageStatus, setShowImageStatus] = useState(false);
  const [toggleShowButton, setToggleShowButton] = useState(true);
  const [showCommentsStatus, setShowCommentsStatus] = useState(false);
  const [toggleShowCommentsButton, setToggleShowCommentsButton] =
    useState(true);
  const [modal, setModal] = useState(false);

  // const apiFetch = new ApiFetch()

  async function fetchApi() {
    const relativePath = getRelativePath(props.location.pathname);
    const response = await resource.getShirt(`${urls.root}/${relativePath}`);
    if (!response) {
      setData({});
    } else {
      setData(response);
    }
  }

  async function handleStatus(resource) {
    switch (resource) {
      case i18n.t("PUBLISH"):
        changePublishStatus(1);
        break;

      case i18n.t("UNPUBLISH"):
        changePublishStatus(0);
        break;
    }
  }

  async function changePublishStatus(statusValue) {
    const relativePath = getRelativePath(props.location.pathname);
    const payload = { isPublished: statusValue };
    const response = await resource.changePusblishedStatus(
      `${urls.root}/${relativePath}`,
      payload
    );

    if (!response) return;
    // setSnackBarMessage(i18n.t('NETWORK_ERROR'));
    else if (response.status >= 400 && response.status <= 500) {
      // setSnackBarMessage(`${response.message} (${response.status})`)
      // setError(true);
    } else {
      // setSnackBarMessage(i18n.t('SUCCESS_CHANGE_STATUS'))
      // setSuccess(true)
      fetchApi();
    }
  }

  const handleConfirmDelete = async () => {
    const relativePath = getRelativePath(props.location.pathname);
    const response = await resource.softDelete(
      `${urls.softDelete}/${relativePath}`
    );
    if (!response || (response.status >= 400 && response.status <= 500)) {
      // setModal(false)
      // setSnackBarMessage(`${res.message} (${res.status})`)
      // setError(true)
    } else {
      setModal(false);
      props.history.push({
        pathname:
          "/shirts" /*state: { snackBar: true, snackBarMessage: i18n.t('USER_SUCCESSFULLY_DELETED') } */,
      });
    }
  };

  const handleShowImage = () => {
    showImageStatus ? setShowImageStatus(false) : setShowImageStatus(true);
    toggleShowButton ? setToggleShowButton(false) : setToggleShowButton(true);
  };

  const handleShowComments = () => {
    showCommentsStatus
      ? setShowCommentsStatus(false)
      : setShowCommentsStatus(true);
    toggleShowCommentsButton
      ? setToggleShowCommentsButton(false)
      : setToggleShowCommentsButton(true);
  };

  const handleDeleteButton = (value) => {
    setModal(true);
  };

  useDeepCompareEffect(() => {
    fetchApi();
  }, [data]);

  return (
    <div>
      {modal && (
        <AlertModalComponent
          itemToDelete={data.shirt}
          cancel={() => setModal(false)}
          handleConfirmDelete={handleConfirmDelete}
          infoMessage={i18n.t(
            "DELETING_THIS_SHIRT_WILL_CAUSE_ALL_DATA_LOOSING"
          )}
          warningMessage={i18n.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SHIRT")}
        />
      )}
      {!data ? (
        "Waiting"
      ) : (
        <Grid container spacing={12} className={classes.main}>
          <Grid item xs={12} sm={6}>
            <List className={classes.root}>
              <h4 className={classes.titleName}>{`${
                data && data.shirt && data.shirt.team.name
              }`}</h4>
              <h1 className={classes.titleName}>{`${
                data && data.shirt && data.shirt.title
              }`}</h1>
              <p
                className={
                  data && data.shirt && data.shirt.isPublished
                    ? classes.publishedStatus
                    : classes.unPublishedStatus
                }
              >
                {data && data.shirt && data.shirt.isPublished
                  ? i18n.t("PUBLISHED")
                  : i18n.t("UNPUBLISHED")}
              </p>
              <p className={classes.statusType}>
                {data && data.shirt && data.shirt.statusType === 0
                  ? i18n.t("IN_EXHIBITION")
                  : data && data.shirt && data.shirt.statusType === 1
                  ? i18n.t("IN_SELL")
                  : data && data.shirt && data.shirt.statusType === 2
                  ? i18n.t("IN_INTERCHANGE")
                  : i18n.t("IN_SELL_OR_INTERCHANGE")}
              </p>

              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <CalendarTodayIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={i18n.t("DATE_REGISTERED")}
                  secondary={
                    data.shirt
                      ? moment(data.shirt.createdAt).format("DD-MM-YYYY")
                      : ""
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PermIdentityIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={i18n.t("USERNAME")}
                  secondary={data.shirt ? data.shirt.shirtUser.username : ""}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AttachMoneyOutlinedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={i18n.t("PRICE")}
                  secondary={
                    (data.shirt && data.shirt.statusType === "SELL") ||
                    (data.shirt && data.shirt.statusType === "SELL_INTERCHANGE")
                      ? data.shirt.price
                      : i18n.t("NOT_APPLIED")
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={i18n.t("DESCRIPTION")}
                  secondary={data.shirt ? data.shirt.description : ""}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LocalOfferIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={i18n.t("BRAND")}
                  secondary={data.shirt ? data.shirt.brand : ""}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PhotoCameraOutlinedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={i18n.t("IMAGES")}
                  secondary={
                    <button onClick={handleShowImage}>
                      {toggleShowButton ? i18n.t("SHOW") : i18n.t("HIDE")}
                    </button>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PeopleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={i18n.t("COMMENTS")}
                  secondary={
                    <button onClick={handleShowComments}>
                      {toggleShowCommentsButton
                        ? i18n.t("SHOW")
                        : i18n.t("HIDE")}
                    </button>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.red}>
                  <DeleteOutlineIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <Button onClick={handleDeleteButton}>
                    {i18n.t("DELETE")}
                  </Button>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  className={
                    data && data.shirt && data.shirt.isPublished
                      ? classes.green
                      : classes.red
                  }
                >
                  {data && data.shirt && data.shirt.isPublished ? (
                    <VerifiedUserIcon />
                  ) : (
                    <BlockIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <div className={classes.statusAcountButtom}>
                    <DropDownButton
                      resource={i18n.t("PUBLISHED_STATE")}
                      options={[i18n.t("PUBLISH"), i18n.t("UNPUBLISH")]}
                      handleStatus={handleStatus}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    ></DropDownButton>
                  </div>
                }
              />
            </ListItem>
          </Grid>

          <Grid item xs={6} sm={12}>
            {showCommentsStatus &&
              data.shirt.comments &&
              data.shirt.comments.length > 0 &&
              data.shirt.comments.map((c) => {
                return <ShowCommentComponent data={c} />;
              })}
          </Grid>

          <Grid item xs={6} sm={12}>
            {showImageStatus && <ImageShowComponent data={data.shirt} />}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
