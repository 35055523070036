const styles = {
    root: {
    },

    table: {
        minWidth: 600,
        maxWidth: 1100,
        fontSize: "0.9em",
        backgroundColor: "#f6f5f5",
        margin: "0 auto"
    },

    theader: {
        backgroundColor: "#f6f5f5",
        fontFamily: 'Roboto Condensed',
        color: '#36425B'
    },

    tbody: {
        color: 'red !important',
    }
};

export default styles;