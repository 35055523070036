import React from 'react';
import ShirtCardComponent from './ShirtCardComponent';


export default function UserMuseumComponent({ userMuseum }) {

    return (
        <span>
            {
                userMuseum &&
                userMuseum.museum &&
                userMuseum.museum.shirts.length > 0 &&
                userMuseum.museum.shirts.map(shirt => {
                    return <ShirtCardComponent shirt={shirt} key={shirt._id} />
                })}
        </span>
    );
}


