import environment from "../../../config/environment"
import ApiFetch from '../../../services/api-fetch';
import getDateFromDays from "../../../helpers/getDateFromDays";

const Resource = function () {

    this.apiFetch = new ApiFetch();

    this.filtered = { createdAt: getDateFromDays(environment.GET_PICTURES_FROM_DAYS) };
    this.sorted = { createdAt: -1 }
    this.pageSize = 500;
    this.pageNum = 1;

    this.getImages = async function (url) {
        return await this.apiFetch.get(url, { filtered: this.filtered, sorted: this.sorted, pageSize: this.pageSize, pageNum: this.pageNum })
    }
};

export default Resource;