import React, { useState } from 'react';
import ApiFetch from '../../services/api-fetch'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import i18n from '../../i18n';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "50px 0 0 50px",
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        textAlign: 'center'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
        textAlign: 'center'
    },
    existingUserContainer: {
        margin: '40px 140px 40px 140px',
        textAlign: 'center',
    },
    errorMessage: {
        border: 'solid 1px black',
        margin: '20px',
        color: 'red',
        fontSize: '0.8em'
    },
    margin: {
        textAlign: 'center !important'
    }
}));


export default function TeamAddComponent(props) {

    const apiFetch = new ApiFetch()

    const classes = useStyles();

    const [newUser, setNewUser] = React.useState(false)
    const [existingUser, setExistingUser] = React.useState(false)
    const [existingUserStatus, setExistingUserStatus] = React.useState(false)

    const [email, setEmail] = React.useState('')
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [role, setRole] = React.useState('')
    const [label, setLabel] = React.useState('');

    const [disabled, setDisabled] = React.useState(false)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    async function create() {
        const url = 'auth/create-user'
        const payload = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            username: username,
            password: password,
            role: role
        }
        const response = await apiFetch.post(url, payload)
        if (response && response.user) {
            props.history.push('/vinimay-team')
        } else {
            setError(true);
            setErrorMessage(response.message)

        }
    }

    const updateRole = async () => {
        const url = `/users/${existingUser.user._id}`
        const payload = {
            role: role
        }
        await apiFetch.updatePut(url, payload)
        props.history.push('/vinimay-team')
    }

    const handleContinueStatus = async () => {
        const url = `users/get-email-user`
        const apiFetch = new ApiFetch()
        const payload = { email: email }
        const response = await apiFetch.post(url, payload)

        if (response.status === 404) {
            setDisabled(true)
            setNewUser(true)
        } else {
            setDisabled(true)
            setExistingUser(response)
            setExistingUserStatus(true)
        }
    }

    const handleRoleChange = (e) => {
        setRole(e.target.value)
        switch (e.target.value) {
            case 1:
                setLabel(i18n.t('ADMIN'));
                break;
            case 2:
                setLabel(i18n.t('EMPLOYEE'));
                break;
            default:
                setLabel('');
                break;
        }
    }

    const handleCancelButton = () => {
        setDisabled(false)
        setNewUser(false)
        setError(false)
        setErrorMessage('')
        setExistingUserStatus(false)
    }

    const handleConfirmButton = () => {
        if (newUser) {
            create()
        } else if (existingUser) {
            updateRole()
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <div>
                    <div className={classes.root}>
                        <div>
                            <TextField
                                disabled={disabled}
                                error={error}
                                id="standard-full-width"
                                style={{ margin: 8 }}
                                placeholder={i18n.t('EMAIL')}
                                helperText={i18n.t('ADD_VINIMAY_TEAM_MEMBER_EMAIL')}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </div>
                    </div>
                    <div className={classes.margin}>
                        {email && /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(email) && (
                            <Button disabled={disabled} onClick={handleContinueStatus} size="small" color="primary" className={classes.margin}>Continue</Button>
                        )}
                    </div>
                </div>
            </Grid>
            {existingUser && (
                <Grid item xs={6}>
                    <p>{i18n.t('USER_ALREADY_REGISTERED_WARNING')}</p>
                    <FormControl variant="filled" className={classes.formControl}>
                        <InputLabel className={classes.input} id="role">Role</InputLabel>
                        <Select
                            className={classes.input}
                            labelId="role"
                            id="role"
                            value={role}
                            onChange={handleRoleChange}
                        >
                            <MenuItem value={""} disabled>
                                <em>Seleccionar</em>
                            </MenuItem>
                            <MenuItem value={1}>{i18n.t('ADMIN')}</MenuItem>
                            <MenuItem value={2}>{i18n.t('EMPLOYEE')}</MenuItem>
                        </Select>
                    </FormControl>
                    <div>
                        <Button disabled={false} onClick={handleCancelButton} size="small" color="secondary" className={classes.margin}>{i18n.t('CANCEL')}</Button>
                        {role && (
                            <Button disabled={false} onClick={handleConfirmButton} size="small" color="primary" className={classes.margin}>{i18n.t('CONFIRM')}</Button>
                        )}
                    </div>
                </Grid>
            )}
            {newUser && (
                <Grid item xs={6}>
                    {error && (
                        <div className={classes.errorMessage}>{errorMessage}</div>
                    )}
                    <div className={classes.existingUserContainer}>
                        <TextField
                            disabled={false}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            placeholder={i18n.t('USERNAME')}
                            helperText={i18n.t('SELECT_USERNAME')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            disabled={false}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            placeholder={i18n.t('FIRST_NAME')}
                            helperText={i18n.t('SELECT_FIRST_NAME')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                            disabled={false}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            placeholder={i18n.t('LAST_NAME')}
                            helperText={i18n.t('SELECT_LAST_NAME')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <TextField
                            error={error}
                            disabled={false}
                            type="password"
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            placeholder={i18n.t('PASSWORD')}
                            helperText={i18n.t('SELECT_PASSWORD')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            disabled={false}
                            type="password"
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            placeholder={i18n.t('C0NFIRM_PASSWORD')}
                            helperText={i18n.t('C0NFIRM_PASSWORD')}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <InputLabel className={classes.input} id="role">{i18n.t('ROLE')}</InputLabel>
                        <Select
                            className={classes.input}
                            labelId="role"
                            id="role"
                            value={role}
                            onChange={handleRoleChange}
                        >
                            <MenuItem value={""} disabled>
                                <em>{i18n.t('SELECT')}</em>
                            </MenuItem>
                            <MenuItem value={1}>{i18n.t('ADMIN')}</MenuItem>
                            <MenuItem value={2}>{i18n.t('EMPLOYEE')}</MenuItem>
                        </Select>
                    </div>
                    <div className={classes.margin}>
                        <Button disabled={false} onClick={handleCancelButton} size="small" color="secondary" className={classes.margin}>{i18n.t('CANCEL')}</Button>
                    </div>
                    {firstName && lastName && password && confirmPassword && role && (
                        <div className={classes.margin}>
                            <Button disabled={false} onClick={handleConfirmButton} size="small" color="primary" className={classes.margin}>{i18n.t('CONFIRM')}</Button>
                        </div>
                    )}
                </Grid>
            )}
        </Grid>
    );
}
