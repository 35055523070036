const urls = {
  getTotalUsers: "dashboard/users/user-count",
  getNewUsers: "bo/users/new-users",

  getNewComments: "bo/comments/new-comments",

  getTotalShirts: "bo/shirts/total",
  getNewShirts: "bo/shirts/new-shirts",

  getMuseumsActivity: "bo/users/activity",
};

export default urls;
