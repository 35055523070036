import React from 'react';
import i18n from "../../i18n"

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        display: 'inline-block',
        maxWidth: 245,
        margin: "50px 50px"

    },
});

export default function ImgMediaCard({ shirt }) {
    const classes = useStyles();

    return (
        <span>
            {shirt && (
                <Card className={classes.root} key={shirt._id}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            height="100%"
                            image={shirt.images[0].cloudImage}
                            title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">{shirt.title}</Typography>
                            <Typography variant="body2" color="textSecondary" component="p">{shirt.description}</Typography>
                            <Typography variant="body2" color="textSecondary" component="p">{shirt.team.name}</Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Link to={`/shirts/view/${shirt._id}`}><Button size="small" color="primary">{i18n.t('SEE_SHIRT')}</Button></Link>
                        <Button size="small" color="primary">{i18n.t('DELETE_SHIRT')}</Button>
                    </CardActions>
                </Card>
            )}
        </span>

    );
}