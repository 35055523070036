module.exports = `

All notable changes to this project will be documented in this file.

The format is based on Keep a Changelog and this project adheres to Semantic Versioning.

## [ 1.4 ] - 2021-03-01 ##


### ADDED ###

* [ STATS ] MAJOR Added museums stats.
* [ USER ] MINOR Implemented filter for verified users

## [ 1.3.1 ] - 2020-11-04 ##


### ADDED ###

* [ HELP ] MINOR Added changelog view to control versioning.

### CHANGED ###

### FIXED ###

* [ STATS ] MAJOR Fixed calendar error.
* [ STATS ] MINOR Fixed display order for Interchange and Sell status in shirts.

----------

## [ 1.3.0 ] - 2020-10-12 ##

### ADDED ###

* [ HOME ] MAJOR Added new card in home displaying all museums that has no activity yet in the app. It has the functionality to go to the details of the museum's user.
* [ COUNTRIES ] MINOR When adding a country implemented functionality to check if there is a possibility that the country has already been created. The script will check if any of the words matches a word in any existing country.
* [ COUNTRIES ] MINOR Added filter by name.
* [ COUNTRIES ] PATCH Added external official list of countries to consult.
* [ USERS ] MAJOR Added see museum option in detail view for user.
* [ USERS ] MINOR Added creation date in main view for users.
* [ SHIRTS ] MINOR Added creation date in main view for shirts.
* [ CONNECTION ] MINOR Handled error when there is no connection with the API.

### CHANGED ###
* [ DATES ] PATCH Parsed to more human legible dates in format DD-MM-YYYY. 

### FIXED ###
* [ COUNTRIES ] MINOR Dropdown showing all countries instead of the firsts 20. Fixed pagination issue.
* [ SHIRTS ] PATCH Display team name in main view.


`