import React, { useEffect, useState } from "react";
import i18n from '../i18n';

function MainHeaderComponent({ data }) {

    const styles = {
        root: {
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: '#36425B',
            marginBottom: "20px",
        },

        title: {
            textAlign: "left",
            fontFamily: "Roboto Condensed",
            fontSize: "1.5em"
        },

        main: {
            margin: "10px 0 0 60px",
            color: "white",
            padding: "20px 0 0 0",
            fontFamily: "Roboto Condensed",
        },

    }

    return (
        <div style={styles.root}>
            <div style={styles.main}>
                <p>v1.4</p>
                {/* <p style={styles.versionNum}>v1.3</p> */}
                <h2 style={styles.title}>{data}</h2>
                <h1 style={styles.title}>{i18n.t('VINIMAY_HOME')}</h1>
            </div>
        </div>
    )
}

export default MainHeaderComponent;