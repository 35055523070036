import Service from './axios-config';

class AuthService {
    constructor() {
        this.service = new Service()
    }

    async login(url, payload) {
        const response = await this.service.post(url, payload, ((status, data) => data))
        return response
    }
}

export default AuthService;