import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import i18n from '../../i18n';

import ApiFetch from '../../services/api-fetch';

import ReactTableComponent from '../table/ReactTableComponent';
import OptionsComponent from '../OptionsComponent';
import AlertModalComponent from '../modals/AlertModalComponent';
import SnackBarComponent from '../SnackBarComponent';


function VinimayTeamIndexComponent(props) {
    const [filters, setFilters] = useState([]);
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize,] = useState(10);
    const [total, setTotal] = useState([0]);
    const [snackBarMessage, setSnackBarMessage] = useState(null);
    const [error, setError] = useState(false);

    const apiFetch = new ApiFetch()

    const columns = React.useMemo(
        () => [
            {
                Header: i18n.t('FIRST_NAME'),
                accessor: 'firstName',
            },
            {
                Header: i18n.t('LAST_NAME'),
                accessor: 'lastName',
            },
            {
                Header: i18n.t('ROLE'),
                accessor: d => parseRole(d.role)
            },
            {
                Header: i18n.t('EMAIL'),
                accessor: 'email',
            },
            {
                id: 'options',
                Header: '',
                Cell: (row, idx) =>
                    [
                        <OptionsComponent
                            key={idx}
                            route={`vinimay-team/view/${row.row.original._id}`}
                            routeEdit={`vinimay-team/edit/${row.row.original._id}`}
                            data={row.row.original}
                            isEditable={true}
                        />
                    ]

            }
        ],
        []
    );

    const parseRole = (role) => {
        switch (role) {
            case 1:
                return i18n.t('ADMIN');
                break;

            case 2:
                return i18n.t('EMPLOYEE');
                break;
        }
    };

    const setPagination = (pageNum) => {
        setPageNum(pageNum)
    }

    const setErrorToFalse = () => {
        setError(false)
    }

    const buildFilters = (filters) => {
        let filtered = {}
        if (!filters) {
            return Object.assign(filtered, { "firstName": '' })
        }
        for (let i = 0; i < filters.length; i++) {
            const value = filters[i].value
            if (filters[i].id === 'firstName') {
                Object.assign(filtered, { "firstName": value })
            } else if (filters[i].id === 'lastName') {
                Object.assign(filtered, { "lastName": value })
            } else if (filters[i].id === 'username') {
                Object.assign(filtered, { "username": value })
            } else if (filters[i].id === 'email') {
                Object.assign(filtered, { "email": value })
            }
        }
        return filtered
    }

    const filtered = buildFilters(filters)

    async function fetchApi() {
        const url = 'bo/users/permitted'
        const response = await apiFetch.get(url, { filtered: filtered })
        if (!response) {
            setSnackBarMessage(i18n.t('NETWORK_ERROR'))
            setError(true)
            setData([])
            setTotal(0)
        } else {
            setData(response.users)
            setTotal(response.total)
        }
    }

    useDeepCompareEffect(() => {
        fetchApi()
    }, [data, filters]);

    return (
        <div>
            <ReactTableComponent
                columns={columns}
                data={data}
                filters={filters}
                setFilters={setFilters}
                onFetchData={fetchApi}
                pageNum={pageNum}
                pageSize={pageSize}
                total={{ total: total, fromDays: null }}
                setPagination={setPagination}
                path={'vinimay-team'}
                addButton={true}
                resourceName={i18n.t('VINIMAY_TEAM')}
            />

            {error && (
                <SnackBarComponent snackBarMessage={snackBarMessage} severity={'error'} setErrorToFalse={setErrorToFalse} />
            )}

        </div>
    )
}

export default VinimayTeamIndexComponent;