import React from 'react'
import { useTable } from 'react-table'

import useStyles from "./styles/minified-react-table/useStyles";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";


function MinifiedReactTableComponent({ columns, data }) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.table}>
                <MaUTable {...getTableProps()}>
                    <TableHead className={classes.theader}>
                        {headerGroups.map((headerGroup, idx) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th  {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()} className={classes.tbody}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map((cell, idx) => {
                                        return (
                                            <TableCell
                                                style={null}
                                                align="center" {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </MaUTable>
            </div >
        </div>

    );
}

export default MinifiedReactTableComponent;