import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import es from './lan/es'

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translations: es
        },
        es: {
            translations: es
        }
    },
    fallbackLng: "es",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
})

export default i18n;
