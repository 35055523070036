const RoleType = {
    USER: 0,
    ADMIN: 1,
    EMPLOYEE: 2,
}

const AuthType = {
    EMAIL: 0,
    FACEBOOK: 1,
    APPLE: 2,
    GOOGLE: 3,
}

const DeviceType = {
    ANDROID: 1,
    APPLE: 2,
    WEB: 3,
    NOT_SPECIFIED: 0,
}

export {
    RoleType,
    AuthType,
    DeviceType
}