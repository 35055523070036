import React, { useState, useEffect } from "react";

import Resource from './resources/Resource';

import urls from "./resources/urls";

import useStyles from "./styles/useStyles";

import i18n from '../../i18n';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


export default function UsersPerDeviceComponent({ date }) {

    const resource = new Resource();

    const classes = useStyles();

    const [usersPerDevice, setUsersPerDevice] = useState([]);

    async function fetchData() {
        const response = await resource.getUsersPerDevice(urls.getUsersPerDevice, date);

        if (!response) {
            console.log("Error")
        }
        setUsersPerDevice(response.usersPerDevice);
    }

    function parseIdToName(id) {
        switch (id) {
            case 1:
                return i18n.t('ANDROID');
                break;
            case 2:
                return i18n.t('IOS');
                break;
            case 3:
                return i18n.t('WEB')
                break;
            case 0:
                return i18n.t('NOT_SPECIFIED')
                break;
            default:
                return i18n.t('WEB')
        }
    }


    useEffect(() => {
        fetchData()
    }, []);


    return (
        <TableContainer className={classes.tableRoot} component={Paper}>
            {usersPerDevice && usersPerDevice.length > 0 && (
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.mainCells}>{i18n.t('DEVICE')}</TableCell>
                            <TableCell className={classes.mainCells}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersPerDevice.map((s) => (
                            <TableRow key={s._id}>
                                <TableCell component="th" scope="row">
                                    {parseIdToName(s._id)}
                                </TableCell>
                                <TableCell align="center">{s.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}

        </TableContainer>
    );
}