const gateDateFromDays = () => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);

  const end = new Date();
  end.setUTCHours(23, 59, 59, 999);

  return {
    from: start,
    to: end,
  };
};

export default gateDateFromDays;
