import environment from "../../../config/environment"
import ApiFetch from '../../../services/api-fetch';
import getDateFromDays from "../../../helpers/getDateFromDays";

const Resource = function () {

    this.apiFetch = new ApiFetch();

    this.getUser = async function (url) {
        return await this.apiFetch.get(url)

    }

    this.getUserMuseum = async function (urlForGetUserMuseum) {
        return {
            userMuseum: await this.apiFetch.get(urlForGetUserMuseum),
        }
    }

    this.changeAccountStatus = async function (url, payload) {
        return {
            response: await this.apiFetch.updatePut(url, payload)
        }
    }

    this.changeUserVerified = async function (url, payload) {
        return {
            response: await this.apiFetch.updatePut(url, payload)
        }
    };

    this.softDelete = async function (url) {
        return await this.apiFetch.get(url)

    }

    this.generatePassword = async function(url){
        return {
            response: await this.apiFetch.get(url),
        }
    }
};

export default Resource;