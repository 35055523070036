import React, { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import moment from "moment";

import i18n from "../../i18n";

import ReactTableComponent from "../table/ReactTableComponent";
import OptionsComponent from "../OptionsComponent";
import AlertModalComponent from "../modals/AlertModalComponent";
import SnackBarComponent from "../SnackBarComponent";

import BlockIcon from "@material-ui/icons/Block";

import ApiFetch from "../../services/api-fetch";

function ShirtIndexComponent(props) {
  const [filters, setFilters] = useState([]);
  const [data, setData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize] = useState(10);
  const [total, setTotal] = useState([0]);
  const [shirtToDelete, setShirtToDelete] = useState(false);
  const [modal, setModal] = useState(false);
  const [sendSnackBar, setSendSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [error, setError] = useState(false);

  const apiFetch = new ApiFetch();

  const columns = React.useMemo(
    () => [
      {
        id: "isPublished",
        filterable: false,
        Header: "",
        width: 10,
        accessor: (row) =>
          !row.isPublished ? (
            <span>
              <BlockIcon style={{ color: "red" }} />
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: i18n.t("USERNAME"),
        accessor: "shirtUser.username",
      },
      {
        Header: i18n.t("TITLE"),
        accessor: "title",
        filter: "fuzzyText",
      },
      {
        Header: i18n.t("BRAND"),
        accessor: "brand",
      },
      {
        Header: i18n.t("TEAM"),
        accessor: "team.name",
      },
      {
        Header: i18n.t("CODE"),
        accessor: (d) => (d.code ? d.code : i18n.t("WITHOUT_CODE")),
      },
      {
        Header: i18n.t("DATE_CREATED"),
        accessor: (d) => moment(d.createdAt).format("DD-MM-YYYY"),
      },
      {
        id: "options",
        Header: "",
        Cell: (row, idx) => [
          <OptionsComponent
            key={idx}
            route={`shirts/view/${row.row.original._id}`}
            data={row.row.original}
            handleDeleteButton={handleDeleteButton}
            canSeeDetail={true}
          />,
        ],
      },
    ],
    []
  );

  const handleDeleteButton = (value) => {
    setModal(true);
    setShirtToDelete(value);
  };

  const handleConfirmDelete = async (value) => {
    const url = `shirts/${value}`;
    const response = await apiFetch.delete(url, { filtered: filtered });
    if (!response || (response.status >= 400 && response.status <= 500)) {
      setModal(false);
      setSnackBarMessage(`${response.message} (${response.status})`);
      setError(true);
    } else {
      setModal(false);
      setSnackBarMessage(i18n.t("SHIRT_SUCCESSFULLY_DELETED"));
      setSendSnackBar(true);
      fetchApi();
    }
  };

  /*
        This two functions setErrorToFalse and setSendSnackBackToFalse 
        are passed to set the properties to false in order
        to be able to render the snackbar again. If not, it will stay true and 
        if another action is triggered without changing the component in the U.I
        the snackbar will not appear, since it is always in true.
    */
  const setErrorToFalse = () => {
    setError(false);
  };

  const setSendSnackBackToFalse = () => {
    setSendSnackBar(false);
  };

  const buildFilters = (filters) => {
    let filtered = {};
    if (!filters) {
      return Object.assign(filtered, { title: "" });
    }
    for (let i = 0; i < filters.length; i++) {
      const value = filters[i].value;
      console.log(filters[i]);
      if (filters[i].id === "title") {
        Object.assign(filtered, { title: value });
      } else if (filters[i].id === "shirtUser.username") {
        Object.assign(filtered, { user: value });
      } else if (filters[i].id === "team.name") {
        Object.assign(filtered, { team: value });
      } else if (filters[i].id === "brand") {
        Object.assign(filtered, { brand: value });
      } else if (filters[i].id === "year") {
        Object.assign(filtered, { year: value });
      } else if (filters[i].id === "statusTypeSellOrInterchange") {
        Object.assign(filtered, { statusTypeSellOrInterchange: value });
      }
    }
    return filtered;
  };

  const filtered = buildFilters(filters);

  const setPagination = (pageNum) => {
    setPageNum(pageNum);
  };

  async function fetchApi() {
    const url = "bo/shirts/all";
    const apiFetch = new ApiFetch();
    const response = await apiFetch.get(url, {
      sorted: { createdAt: "-1" },
      filtered: filtered,
      pageSize: pageSize,
      pageNum: pageNum,
    });
    if (!response) {
      setSnackBarMessage(i18n.t("NETWORK_ERROR"));
      setError(true);
      setData([]);
      setTotal(0);
    } else {
      setData(response.shirts);
      setTotal(response.total);
    }
  }

  useDeepCompareEffect(() => {
    fetchApi();
  }, [data, filters, pageNum, pageSize]);

  const shirtStatusCheckBoxHandler = (value) => {
    console.log(value);
    setFilters([{ id: "statusTypeSellOrInterchange", value: value ? 1 : 0 }]);
  };

  const statusTypeSellOrInterchangeOpts = {
    type: "checkbox",
    property: "statusTypeSellOrInterchange",
    setFilters: shirtStatusCheckBoxHandler,
    color: "primary",
    label: i18n.t("SHOW_SHIRT_STATUS"),
  };

  return (
    <div>
      <ReactTableComponent
        columns={columns}
        data={data}
        filters={filters}
        setFilters={setFilters}
        setPagination={setPagination}
        onFetchData={fetchApi}
        pageNum={pageNum}
        pageSize={pageSize}
        total={{ total: total, fromDays: null }}
        sendSnackBar={sendSnackBar}
        snackBarMessage={snackBarMessage}
        setSendSnackBackToFalse={setSendSnackBackToFalse}
        resourceName={i18n.t("SHIRTS")}
        opts={statusTypeSellOrInterchangeOpts}
      />
      {modal && (
        <AlertModalComponent
          itemToDelete={shirtToDelete}
          cancel={() => setModal(false)}
          handleConfirmDelete={handleConfirmDelete}
          infoMessage={i18n.t(
            "DELETING_THIS_SHIRT_WILL_CAUSE_ALL_DATA_LOOSING"
          )}
          warningMessage={i18n.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SHIRT")}
        />
      )}
      {error && (
        <SnackBarComponent
          snackBarMessage={snackBarMessage}
          severity={"error"}
          setErrorToFalse={setErrorToFalse}
        />
      )}
    </div>
  );
}

export default ShirtIndexComponent;
