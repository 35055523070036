import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect'

import ApiFetch from '../../services/api-fetch'

import i18n from '../../i18n'

import getRelativePath from '../../helpers/getRelativePath'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "20px 0 0 50px",
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function TeamDetailViewComponent(props) {
    const classes = useStyles();

    const [data, setData] = useState({});

    async function fetchApi() {
        const relativePath = getRelativePath(props.location.pathname)
        const url = `teams/${relativePath}`
        const apiFetch = new ApiFetch()
        const response = await apiFetch.get(url)
        setData(response)
    }

    useDeepCompareEffect(() => {
        fetchApi()
    }, [data]);


    return (
        <List className={classes.root}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <SportsSoccerIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={i18n.t('TEAM')} secondary={data.name} />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <LocationOnIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={i18n.t('COUNTRY')} secondary={data.state} />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <FingerprintIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={i18n.t('UNIQUE_ID')} secondary={data.unique_id} />
            </ListItem>
        </List>
    );
}
