import React, { useState, useEffect } from "react";
import SnackBarComponent from "../SnackBarComponent";
import i18n from "../../i18n";
import useStyles from "./styles/useStyles";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";

import Resource from "./resources/Resource";
import urls from "./resources/urls";
import filterEmptyMuseums from "./helpers/filterEmptyMuseums";

import Grid from "@material-ui/core/Grid";

import CardComponent from "./CardComponent";

function HomeCompoent() {
  const resource = new Resource();

  const [usersCardInfo, setUsersCardInfo] = useState(null);
  const [shirtsCardInfo, setShirtsCardInfo] = useState(null);
  const [commentsCardInfo, setCommentCardInfo] = useState(null);
  const [museumsCardInfo, setMuseumsCardInfo] = useState(null);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dateToday = moment(new Date()).format("DD-MM-YYYY");
  console.log(dateToday);
  const classes = useStyles();

  async function fetchData() {
    const _usersCardInfo = await resource.getInfo(
      urls.getTotalUsers,
      urls.getNewUsers
    );

    if (!_usersCardInfo.new && !_usersCardInfo.total) {
      setError(true);
      setErrorMessage(i18n.t("NETWORK_ERROR"));
    }

    const _shirtsCardInfo = await resource.getInfo(
      urls.getTotalShirts,
      urls.getNewShirts
    );

    if (!_shirtsCardInfo.new && !_shirtsCardInfo.total) {
      setError(true);
      setErrorMessage(i18n.t("NETWORK_ERROR"));
    }

    const _commentsCardInfo = await resource.getInfo(
      urls.getTotalComments,
      urls.getNewComments
    );

    if (!_commentsCardInfo.new && _commentsCardInfo.total) {
      setError(true);
      setErrorMessage(i18n.t("NETWORK_ERROR"));
    }

    const _museumCardInfo = await resource.getMuseumsActivity(
      urls.getMuseumsActivity
    );
    const emptyMuseumsInfo = filterEmptyMuseums(_museumCardInfo);

    setUsersCardInfo(_usersCardInfo);
    setShirtsCardInfo(_shirtsCardInfo);
    setCommentCardInfo(_commentsCardInfo);
    setMuseumsCardInfo(emptyMuseumsInfo);
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return (
      <SnackBarComponent snackBarMessage={errorMessage} severity={"error"} />
    );
  } else {
    return (
      <div className={classes.root}>
        {/* <div className={classes.main}>
          <div className={classes.card}>
            <div className={classes.date}>
              <MenuItem>{dateToday}</MenuItem>
            </div>

            <Grid container className={classes.gridContainer}>
              <Grid item xs>
                <CardComponent
                  dataNew={usersCardInfo ? usersCardInfo.new.newUsers : null}
                  title={i18n.t("USERS")}
                  info={{
                    total: usersCardInfo ? usersCardInfo.total.totalUsers : 0,
                    newTotal: usersCardInfo ? usersCardInfo.new.total : 0,
                    identifier: "users",
                    showInfo: { total: true, new: true },
                  }}
                />
              </Grid>

              <Grid item xs>
                <CardComponent
                  dataNew={shirtsCardInfo ? shirtsCardInfo.new.newShirts : null}
                  title={i18n.t("SHIRTS")}
                  info={{
                    total: shirtsCardInfo
                      ? shirtsCardInfo.total.totalShirts
                      : 0,
                    newTotal: shirtsCardInfo ? shirtsCardInfo.new.total : 0,
                    identifier: "shirts",
                    showInfo: { total: true, new: true },
                  }}
                />
              </Grid>

              <Grid item xs>
                <CardComponent
                  dataNew={
                    commentsCardInfo ? commentsCardInfo.new.newComments : null
                  }
                  title={i18n.t("COMMENTS")}
                  info={{
                    total: commentsCardInfo
                      ? commentsCardInfo.total.totalComments
                      : 0,
                    newTotal: commentsCardInfo ? commentsCardInfo.new.total : 0,
                    identifier: "comments",
                    showInfo: { total: false, new: true },
                  }}
                />
              </Grid>

              <Grid item xs>
                <CardComponent
                  dataEmpty={museumsCardInfo ? museumsCardInfo : null}
                  title={i18n.t("MUSEUMS")}
                  info={{
                    emptyTotal: museumsCardInfo ? museumsCardInfo.length : 0,
                    identifier: "museums",
                    showInfo: { total: false, new: false, empty: true },
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div> */}
      </div>
    );
  }
}

export default HomeCompoent;
