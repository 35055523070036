import React from "react";
import moment from 'moment';
import { Divider, Avatar, Grid, Paper } from "@material-ui/core";
import i18n from "../../i18n";

const imgLink =
    "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

function ShowCommentComponent({ data }) {
    console.log("data", data)
    return (

        <div>
            <Paper style={{ padding: "40px 20px" }}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar alt="Remy Sharp" src={data.commentUser && data.commentUser.image && data.commentUser.image.cloudImage} />
                    </Grid>
                    <Grid justifyContent="left" item xs zeroMinWidth>
                        <h4 style={{ margin: 0, textAlign: "left" }}>{data.commentUser.username}</h4>
                        <p style={{ textAlign: "left" }}>
                            {data.text}
                        </p>
                        <p style={{ textAlign: "left", color: "gray" }}>
                            {i18n.t('COMMENT_MADE_ON_DATE')} {moment(data.createdAt).format('DD-MM-YYYY')}
                        </p>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
            </Paper>
        </div>
    );
}

export default ShowCommentComponent;