import React, { useState } from "react";
import useDeepCompareEffect from 'use-deep-compare-effect'

import useStyles from "./styles/useStyles";

import MinifiedReactTableComponent from "../table/MinifiedReactTableComponent";
import columns from "./table-colunms/viewNewComponentColumns";
import i18n from "../../i18n";

function ViewNewComponent(props) {
    const [columnsTable, setColumnsTable] = useState([]);

    const classes = useStyles();

    function selectColumns() {
        if (props && props.location && props.location.state) {
            switch (props.location.state.identifier) {
                case 'users':
                    setColumnsTable(columns.users);
                    break;

                case 'shirts':
                    setColumnsTable(columns.shirts);
                    break;

                case 'comments':
                    setColumnsTable(columns.comments);
                    break;

                default:
                    setColumnsTable([]);
            }
        }
    };

    useDeepCompareEffect(() => {
        selectColumns()
    }, [columnsTable]);


    return (
        <div >
            {props && props.location && props.location.state && (
                <MinifiedReactTableComponent
                    data={props.location.state.data}
                    columns={columnsTable}
                />
            )}

        </div>
    )
}

export default ViewNewComponent;