import React, { useState, useMemo } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import i18n from '../../i18n'

import ReactTableComponent from '../table/ReactTableComponent'
import OptionsComponent from '../../components/OptionsComponent'
import SnackBarComponent from '../SnackBarComponent'

import ApiFetch from '../../services/api-fetch'
import { I18nContext } from 'react-i18next'

function CountryIndexComponent(props) {
    const [filters, setFilters] = useState([]);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([0])
    const [pageNum, setPageNum] = useState(1)
    const [pageSize] = useState(20)
    const [sendSnackBar, setSendSnackBar] = useState(false)
    const [snackBarMessage, setSnackBarMessage] = useState(null)
    const [error, setError] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: i18n.t('NAME'),
                accessor: 'name.es',
                filterable: true,
            },
            {
                id: 'options',
                Header: '',
                Cell: (row, idx) => [
                    <OptionsComponent
                        key={idx}
                        route={`countries/view/${row.row.original._id}`}
                        data={row.row.original}
                        isEditable={true}
                        routeEdit={`countries/add`}
                    />
                ]

            }
        ],
        []
    )

    const buildFilters = (filters) => {
        let filtered = {}
        if (!filters) {
            return Object.assign(filtered, { "name": '' })
        }
        for (let i = 0; i < filters.length; i++) {
            const value = filters[i].value
            if (filters[i].id === 'name') {
                Object.assign(filtered, { "name": value })
            }
        }
        return filtered
    }

    const filtered = buildFilters(filters)

    async function fetchApi() {
        const url = 'countries'
        const apiFetch = new ApiFetch()
        const response = await apiFetch.get(url, { sorted: { createdAt: '-1' }, filtered: filtered, pageSize: pageSize, pageNum: pageNum })
        if (!response) {
            setSnackBarMessage(i18n.t('NETWORK_ERROR'));
            setError(true)
            setData([])
        } else {
            setData(response.countries);
            setTotal(response.total);
        };
    }

    const setPagination = (pageNum) => {
        setPageNum(pageNum)
    }

    useDeepCompareEffect(() => {
        fetchApi()
    }, [data, filters, pageNum, pageSize]);


    /*
        This two functions setErrorToFalse and setSendSnackBackToFalse 
        are passed to set the properties to false in order
        to be able to render the snackbar again. If not, it will stay true and 
        if another action is triggered without changing the component in the U.I
        the snackbar will not appear, since it is always in true.
    */
    const setErrorToFalse = () => {
        setError(false)
    }

    const setSendSnackBackToFalse = () => {
        setSendSnackBar(false)
    }

    return (
        <div>
            <ReactTableComponent
                columns={columns}
                data={data}
                filters={filters}
                setFilters={setFilters}
                setPagination={setPagination}
                onFetchData={fetchApi}
                pageNum={pageNum}
                pageSize={pageSize}
                total={{ total: total, fromDays: null }}
                sendSnackBar={sendSnackBar}
                snackBarMessage={snackBarMessage}
                setSendSnackBackToFalse={setSendSnackBackToFalse}
                addButton={true}
                path={'countries'}
                resourceName={i18n.t('COUNTRIES')}
            />
            {error && (
                <SnackBarComponent snackBarMessage={snackBarMessage} severity={'error'} setErrorToFalse={setErrorToFalse} />
            )}
        </div>
    )
}

export default CountryIndexComponent;