import React, { useState } from "react";
import AuthService from "../../services/auth.service";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";

import i18n from "../../i18n";

function LoginComponent(props) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      container: {
        display: "flex",
        flexWrap: "wrap",
        width: 400,
        margin: `${theme.spacing(0)} auto`,
      },
      loginBtn: {
        marginTop: theme.spacing(2),
        flexGrow: 1,
      },
      header: {
        textAlign: "center",
        background: "#36425B",
        color: "#fff",
      },
      card: {
        marginTop: theme.spacing(10),
      },
    })
  );

  const classes = useStyles();
  const [email, setEmail] = React.useState([]);
  const [password, setPassword] = React.useState([]);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleDisableButton = (e) => {
    const emailRegex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return email && emailRegex.test(email) && password && password.length >= 6
      ? false
      : true;
  };

  async function login() {
    const url = "auth/login";
    const auth = new AuthService();
    const payload = Object.freeze({
      email: email,
      password: password,
      isBackOffice: true,
    });
    const response = await auth.login(url, payload);
    console.log(response);
    if (!response) {
      setError(true);
      setHelperText(i18n.t("NETWORK_ERROR"));
    } else if (response.status >= 400) {
      setError(true);
      setHelperText(response.message);
    } else if (response && response.user && response.token) {
      setError(false);
      console.log("ENTERS");
      localStorage.setItem("jwt-token", response.token);
      props.history.push("/");
    }
  }

  return (
    <div>
      <React.Fragment>
        <form className={classes.container} noValidate autoComplete="off">
          <Card className={classes.card}>
            <CardHeader className={classes.header} title="Vinimay App" />
            <CardContent>
              <div>
                <TextField
                  error={error}
                  fullWidth
                  id="username"
                  type="email"
                  label={i18n.t("USERNAME")}
                  placeholder={i18n.t("USERNAME")}
                  margin="normal"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  error={error}
                  fullWidth
                  id="password"
                  type="password"
                  label={i18n.t("PASSWORD")}
                  placeholder={i18n.t("PASSWORD")}
                  margin="normal"
                  helperText={helperText}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.loginBtn}
                onClick={() => login()}
                disabled={handleDisableButton()}
              >
                {i18n.t("LOGIN")}
              </Button>
            </CardActions>
          </Card>
        </form>
      </React.Fragment>
    </div>
  );
}

export default LoginComponent;
