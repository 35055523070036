const es = Object.freeze({
  ADD_ITEM: "Agregar",
  ADD_COUNTRY: "Agregar país",
  ADD_COUNTRY_MESSAGE:
    "Por favor ingresa el nombre del país que quieres agregar",
  ADDRESS: "Dirección",
  ADMIN: "Administrador",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SHIRT:
    "Está seguro que desea continuar con la eliminación de esta camiseta?",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMMENT:
    "Está seguro que desea continuar con la eliminación de este comentario?",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COUNTRY:
    "Está seguro que desea continuar con la eliminación de este país?",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER:
    "Está seguro que desea continuar con la eliminación de este usuario?",
  ACCOUNTS: "Cuentas",
  ADD_VINIMAY_TEAM_MEMBER_EMAIL: "Agrega el email",
  ACCOUNT_STATE: "Estado de cuenta",
  AUTH_TYPE: "Tipo de autenticación",
  APPLE: "Apple",

  BRAND: "Marca",
  BY_COUNTRY: "Por país",
  BY_TEAM: "Por equipo",
  BY_STATE: "Por estado",
  BAN: "Banear",
  BANNED_ACCOUNT: "Cuenta baneada",
  BY_MUSEUM: "Por museo",
  BY_DEVICE: "Por dispositivo",
  ANDROID: "Android",

  COUNTRY: "País",
  COUNTRIES_REFERENCE:
    "Para una referencia, y convenciones de nombres puedes revisar: ",
  COUNTRY_LIST: "Listado de países",
  CITY: "Ciudad",
  CONTINUE: "Continuar",
  CONFIRM: "Confirmar",
  CANCEL: "Cancelar",
  CODE: "Código",
  COMMENT_SUCCESSFULLY_DELETED: "Comentario eliminado con éxito",
  COMMENTS: "Comentarios",
  COUNTRIES: "Países",
  COUNTRY_SUCCESSFULLY_DELETED: "País eliminado con éxito",
  C0NFIRM_PASSWORD: "Confirma la contraseña",
  CONFIRM_CHANGE_ROLE: "Estas seguro que deseas cambiar el rol de ",
  CHECK_COUNTRIES: "Revisar paises",
  CHOOSE_DATE: "Elige la fecha de estadísiticas",
  CONTROL_VERSIONING: "Control de versiones",
  CREATE_NEW_TEAM: "Crear equipo nuevo",
  COMMENT_MADE_ON_DATE: "Comentario hecho el día",
  CONFIRM: "Confirmar",

  DATE_CREATED: "Fecha de creación",
  DATE_UPDATED: "Fecha de actualización",
  DATE_REGISTERED: "Fecha de registro",
  DESACTIVE: "Desactivar",
  DELETE: "Eliminar",
  DELETING_THIS_SHIRT_WILL_CAUSE_ALL_DATA_LOOSING:
    "Al eliminar esta camiseta, se perderán de forma definitiva su información, likes y comentarios",
  DELETING_THIS_COMMENT_WILL_CAUSE_ALL_DATA_LOOSING:
    "Si eliminas este comentario, el mismo se perderá definitivamente",
  DELETING_THIS_COUNTRY_WILL_CAUSE_ALL_DATA_LOOSING:
    "Al eliminar este país todas la camisetas asociadas al mismo lo perderán",
  DELETING_THIS_USER_WILL_CAUSE_ALL_DATA_LOOSING:
    "Al eliminar el usuario, se perderán de forma definitiva su información, museo, camisetas, imágenes y comentarios",
  DESCRIPTION: "Descripción",
  DELETE_SHIRT: "Eliminar camiseta",
  DEVICE: "Dispositivo",

  EMAIL: "Email",
  EMPLOYEE: "Empleado",
  EXHIBITION: "Exhibición",
  EDIT_TEAM: "Editar equipo",
  EDIT_COUNTRY: "Editar País",

  FIRST_NAME: "Nombre",
  FULL_NAME: "Nombre completo",
  FROM_DAYS: "De días",
  FACEBOOK: "Facebook",

  GENDER: "Sexo",
  GOOGLE: "Google",
  GENERATE_PASSWORD: "Generar contraseña",

  HIDE: "Esconder",
  HOME: "Home",
  HELP: "Ayuda",

  ID: "Id",
  IMAGES: "Imágenes",
  IS_DUPLICATED: "Sí es duplicado",
  IS_NOT_DUPLICATED: "No es duplicado",
  INTERCHANGE: "Intercambio",
  INTERCHANGE_SELL: "Intercambio y venta",
  IOS: "IOS",
  ISO_CODE_ALPHA_2: "Código ISO alpha 2",
  ISO_CODE_ALPHA_3: "Código ISO alpha 3",
  ID_NUMBER: "Nro. ID",
  IN_EXHIBITION: "En exhibición",
  IN_SELL: "A la venta",
  IN_INTERCHANGE: "Para intercambio",
  IN_SELL_OR_INTERCHANGE: "Para venta o intercambio",

  LAST_NAME: "Apellido",
  LOGIN: "Iniciar sesión",

  MUSEUMS: "Museos",
  MUSEUMS_WITH_NO_ACTIVITY: "Museos sin actividad",

  NAME: "Nombre",
  NETWORK_ERROR: "Error de conexión",
  NEW: "Nuevos",
  NOT_SPECIFIED: "Sin especificar",
  NAME_SPANISH: "Nombre en español",
  NAME_ENGLISH: "Nombre en inglés",
  NOT_APPLIED: "No aplica",

  PHONE_NUMBER: "Número de teléfono",
  PASSWORD: "Contraseña",
  PERMISSION_GROUP: "Grupo",
  POSSIBLE_DUPLICATED:
    "El páis que intentas ingresar tiene un posible duplicado",
  POSSIBLE_DUPLICATED_NEXT_ACTION: "Por favor revisa otra vez y continúa",
  PUBLISHED_STATE: "Estado de publicación",
  PUBLISH: "Publicar",
  PUBLISHED: "Publicada",
  PRICE: "Precio",

  RESET_FILTERS: "Reestablecer filtros",
  ROLE: "Rol",

  TOTAL: "Total",

  SHIRT_BLOCKED: "Camiseta bloqueada",
  SHIRT_ACTIVE: "Camiseta activa",
  SET_ACTIVE: "Activar",
  SET_BLOCKED: "Bloquear",
  SUCCESS_CHANGE_STATUS: "Status cambiado con éxtio",
  SUCCESS_VERIFIED_STATUS: "Status de perfil actualizado correctamente",
  STATE: "Estado",
  SELECT_THE_COUNTRY_OF_THE_TEAM: "Selecciona el país del equipo",
  SELECT_COUNTRY: "Selecciona el país",
  SELECT_USERNAME: "Selecciona el nombre de usuario",
  SELECT_FIRST_NAME: "Selecciona el nombre",
  SELECT_LAST_NAME: "Selecciona el apellido",
  SELECT_PASSWORD: "Selecciona una contraseña",
  SELECT_ROLE_WARNING:
    "Elije el rol que quieres asignar a este usuario. Ten en cuenta que los permisos de administración otorgan plenas facultades para escribir, editar y eliminar",
  SELECT: "Seleccionar",
  SHOW: "Show",
  SHIRT_SUCCESSFULLY_DELETED: "Camiseta eliminada con éxito",
  SHIRTS: "Camisetas",
  STATS: "Estadísticas",
  SEE_NEW: "Ver nuevos",
  SEE_EMPTY: "Ver sin actividad",
  SEE_SHIRT: "Ver camiseta",
  SEE_MUSEUM: "Ver museo",
  SELL: "Venta",
  SHOW_VERIFIED: "Mostrar verificados",
  SHOW_SHIRT_STATUS: "Ver a la venta y/o intercambio",

  TEXT: "Texto",
  TITLE: "Título",
  TEAM_SUCCESSFULLY_ADDED: "Equipo agregado con éxito",
  TEAM: "Equipo",
  TEAMS: "Equipos",

  USERNAME: "Nombre de usuario",
  USER_BLOCKED: "Usuario bloqueado",
  USER_ACTIVE: "Usuario activo",
  USER_NOT_VERIFIED: "Usuario no verificado",
  USER_VERIFIED: "Usuario verificado",
  UNIQUE_ID: "Id único",
  USER: "Usuario",
  USERS: "Usuarios",
  USER_SUCCESSFULLY_DELETED: "Usuario eliminado con éxito",
  USER_ALREADY_REGISTERED_WARNING:
    "Este usuario se encuentra registrado como usuario regular. Si ha pasado a ser un miembro de vinimay y deseas asinarle un nuevo rol, cambialo aquí. De lo contrario pulsa cancelar",
  UNVERIFY: "No verificar",
  UNVERIFIED_ACCOUNT: "Cuenta sin verificar",
  UNPUBLISH: "Retirar",
  UNPUBLISHED: "No publicada",
  URL_FLAG_IMAGE: "Imágen de bandera (URL)",
  PASSWORD_WILL_BE_REPLACED_MESSAGE:
    "Al aceptar, se cambiará la contraseña del usuario y se enviará por correo electrónico una nueva",
  PASSWORD_WILL_NOT_BE_SHOWN_MESSAGE:
    "Ten en cuenta que por motivos de seguridad esta contraseña no será mostrada y solo el usuario podrá verla en su correo",
  PASSWORD_SUCCESSFULLY_GENERATED: "Contraseña generada con éxito",

  VERIFY: "Verificar",
  VERIFIED_ACCOUNT: "Cuenta verificada",
  VINIMAY_TEAM: "Equipo vinimay",
  VINIMAY_HOME: "VINIMAY",

  WRITE_TEAM_NAME: "Escribe el nombre del equipo",
  WELCOME: "Entorno de administración",
  WITHOUT_ACTIVITY: "Sin actividad",
  WITHOUT_CODE: "Sin código",
  WEB: "Web",
});

export default es;
