import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels(props) {
  // const [state, setState] = React.useState({
  //     isVerified: false,
  //     // checkedB: true,
  //     // checkedF: true,
  //     // checkedG: true,
  // });

  const [isChecked, setIsChecked] = useState(true);
  const handleChange = (event) => {
    switch (props.opts.property) {
      case "isVerified":
        setIsChecked(!isChecked);
        props.opts.setFilters(isChecked);
        break;
      case "statusTypeSellOrInterchange":
        setIsChecked(!isChecked);
        props.opts.setFilters(isChecked);
        break;
    }
  };

  return (
    <FormGroup row>
      {/* <FormControlLabel
                control={<Checkbox checked={state.checkedA} onChange={handleChange} name="checkedA" />}
                label="Secondary"
            /> */}
      <FormControlLabel
        control={
          <Checkbox
            //checked={isChecked}
            onChange={handleChange}
            name={props.opts.property}
            color="primary"
          />
        }
        label={props.opts.label}
      />
      {/* <FormControlLabel control={<Checkbox name="checkedC" />} label="Uncontrolled" />
            <FormControlLabel disabled control={<Checkbox name="checkedD" />} label="Disabled" />
            <FormControlLabel disabled control={<Checkbox checked name="checkedE" />} label="Disabled" />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={state.checkedF}
                        onChange={handleChange}
                        name="checkedF"
                        indeterminate
                    />
                }
                label="Indeterminate"
            />
            <FormControlLabel
                control={<GreenCheckbox checked={state.checkedG} onChange={handleChange} name="checkedG" />}
                label="Custom color"
            />
            <FormControlLabel
                control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" />}
                label="Custom icon"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="checkedI"
                    />
                }
                label="Custom size"
            /> */}
    </FormGroup>
  );
}
