import i18n from '../../../i18n'

const columns = {
    users: [
        {
            Header: i18n.t('FIRST_NAME'),
            accessor: 'firstName',
            filterable: false,
        },
        {
            Header: i18n.t('LAST_NAME'),
            accessor: 'lastName',
            filterable: false,
        },
        {
            Header: i18n.t('USERNAME'),
            accessor: 'username',
            filterable: false,
        },
        {
            Header: i18n.t('EMAIL'),
            accessor: 'email',
            filterable: false,
        },
    ],
    shirts: [
        {
            Header: i18n.t('USERNAME'),
            accessor: 'shirtUser.username',
        },
        {
            Header: i18n.t('TITLE'),
            accessor: 'title',
            filter: 'fuzzyText',
        },
        {
            Header: i18n.t('BRAND'),
            accessor: 'brand',
        },
        {
            Header: i18n.t('CODE'),
            accessor: d => d.code ? d.code : i18n.t('WITHOUT_CODE')
        },
        {
            Header: i18n.t('TEAM'),
            accessor: 'team.name',
        },
    ],
    comments: [
        {
            Header: i18n.t('USERNAME'),
            accessor: 'commentUser.username',
        },
        {
            Header: i18n.t('TEXT'),
            accessor: 'text',
        },
    ]
}

export default columns;