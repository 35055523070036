import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { RoleType } from '../../types/UserTypes';
import Sidebar from "react-sidebar";
import defaultProfile from './24-248253_user-profile-default-image-png-clipart-png-download.png'

import i18n from '../../i18n'

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';


function SideBar(props) {
    const mql = window.matchMedia(`(min-width: 800px)`);

    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [sidebarDocked] = React.useState(mql.matches)

    const listItems = () => {
        const protectedRoutes = [7, 8, 9]
        let items = [
            { id: 1, value: i18n.t('HOME'), route: '/' },
            { id: 2, value: i18n.t('USERS'), route: '/users' },
            { id: 3, value: i18n.t('SHIRTS'), route: '/shirts' },
            { id: 4, value: i18n.t('TEAMS'), route: '/teams' },
            { id: 5, value: i18n.t('COUNTRIES'), route: '/countries' },
            { id: 6, value: i18n.t('COMMENTS'), route: '/comments' },
            { id: 7, value: i18n.t('IMAGES'), route: '/images' },
            { id: 8, value: i18n.t('VINIMAY_TEAM'), route: '/vinimay-team' },
            { id: 9, value: i18n.t('ACCOUNTS'), route: '/accounts' },
            { id: 10, value: i18n.t('STATS'), route: 'stats' },
            { id: 11, value: i18n.t('HELP'), route: 'help' },
        ]
        if (props.user.role !== RoleType.ADMIN) {
            items = items.filter((item) => {
                return !protectedRoutes.includes(item.id)
            })
        }
        return items.map((item) => {
            return <li className="nav-item" key={item.id}><Link to={{ pathname: item.route, user: props.user }} className='btn' onClick={item.logout}>{item.value} </Link></li>
        })
    }

    const parseRoleValue = (role) => {
        console.log(role)

        switch (role) {
            case 1:
                return i18n.t('ADMIN');
                break;
            case 2:
                return i18n.t('EMPLOYEE');
                break;
            case 0:
                return i18n.t('USER');
                break;
        }
    }


    const onSetSidebarOpen = () => {
        setSidebarOpen(true)
    }

    const logout = () => {
        localStorage.removeItem('jwt-token')
        props.history.push('/login')
    }

    React.useEffect(() => {
        onSetSidebarOpen()
    }, [])

    return (
        <Sidebar
            sidebar={
                <div style={{ textAlign: "center", fontFamily: 'Fjalla One' }}>
                    <div>
                        <figcaption style={{ textAlign: "center" }}>
                            <Link to={'/profile'} className="profile-pic-link">
                                <img className="profile-pic rounded-circle" src={defaultProfile} alt={'profile-img'} />
                            </Link>
                            <div className="user-info-container">
                                <p className="user-profile-list">{props.user.email}</p>
                                <p className="role user-profile-list">{parseRoleValue(props.user.role)}</p>
                            </div>
                        </figcaption>
                    </div>
                    {listItems()}
                    <IconButton onClick={logout}>
                        <Icon className="nav-item">exit_to_app</Icon>
                    </IconButton>
                </div>
            }
            open={sidebarOpen}
            docked={sidebarDocked}
            onSetOpen={onSetSidebarOpen}
            styles={{
                sidebar: {
                    background: "#36425B",
                    navigator: "10em",
                },
                overlay: { background: "white" },

            }}
        >

            {props.children}
        </Sidebar>
    )

}

export default withRouter(SideBar);