import environment from "../../../config/environment";
import ApiFetch from "../../../services/api-fetch";
import getDateFromDays from "../../../helpers/getDateFromDays";
import { startOfDay } from "date-fns";

const Resource = function () {
  this.apiFetch = new ApiFetch();

  // Filter will always be the same => createdAt lt 1 day
  const date = getDateFromDays();
  this.filetered = date;
  this.sorted = { createdAt: -1 };

  this.getInfo = async function (urlForTotal, urlForNew) {
    return {
      total: await this.apiFetch.get(urlForTotal),
      new: await this.apiFetch.get(urlForNew, {
        filtered: this.filetered,
        sorted: this.sorted,
      }),
    };
  };

  this.getMuseumsActivity = async function (getUserMuseumsActivityUrl) {
    return await this.apiFetch.get(getUserMuseumsActivityUrl);
  };
};

export default Resource;
