import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import useStyles from "./styles/useStyles";

import i18n from '../../i18n';

import TeamsPerShirtsComponent from './TeamsPerShirtsComponent';
import CountriesPerShirtComponent from './CountriesPerShirtComponents';
import ShirtsPerStateComponent from './ShirtsPerStateComponent';
import MainHeaderComponent from '../MainHeaderComponent';
import ShirtsPerMuseumComponent from './ShirtsPerMuseumComponent';
import UsersPerDeviceComponent from './UsersPerDeviceComponent';

import DropDownButton from '../DropDownButton';
import DatePickerComponent from '../DatePickerComponent';
import Grid from '@material-ui/core/Grid';

function StatsComponent() {
    const [shirtsPerTeamStatus, setShirtsPerTeamStaus] = useState(false);
    const [shirtsPerCountryStatus, setShirtsPerCountryStaus] = useState(false);
    const [shirtsPerStateStatus, setShirtsPerStateStatus] = useState(false);
    const [shirtsPerMuseumStatus, setShirtsPerMuseumStatus] = useState(false);
    const [userPerDeviceStatus, setUserPerDeviceStatus] = useState(false);
    const [date, setDate] = useState(new Date());


    const classes = useStyles();

    function handleShirtStatsStatus(resource) {
        setShirtsPerMuseumStatus(false);
        setUserPerDeviceStatus(false);

        switch (resource) {
            case i18n.t('BY_TEAM'):
                setShirtsPerTeamStaus(true);
                break;

            case i18n.t('BY_COUNTRY'):
                setShirtsPerCountryStaus(true)
                break;

            case i18n.t('BY_STATE'):
                setShirtsPerStateStatus(true)
                break;
        }
    }

    function handleUsersStatsStatus(resource) {
        setShirtsPerTeamStaus(false);
        setShirtsPerCountryStaus(false)
        setShirtsPerStateStatus(false)

        switch (resource) {
            case i18n.t('BY_MUSEUM'):
                setShirtsPerMuseumStatus(true);
                break;

            case i18n.t('BY_DEVICE'):
                setUserPerDeviceStatus(true)
                break;
        }
    }


    function handleChangeDate(date) {
        setShirtsPerTeamStaus(false);
        setShirtsPerCountryStaus(false);
        setShirtsPerStateStatus(false);

        setDate(date);
    }

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <div>
                    <div>
                        <label className={classes.calendar_label}>{i18n.t('CHOOSE_DATE')}</label>
                        <DatePickerComponent handleChangeDate={handleChangeDate} />
                    </div>
                    <Grid container>
                        <DropDownButton
                            resource={i18n.t('SHIRTS')}
                            options={[i18n.t('BY_TEAM'), i18n.t('BY_COUNTRY'), i18n.t('BY_STATE')]}
                            handleStatus={handleShirtStatsStatus}
                            className={classes.button}
                            variant="contained"
                            color="primary">
                        </DropDownButton>
                        <DropDownButton
                            resource={i18n.t('USERS')}
                            options={[i18n.t('BY_MUSEUM'), i18n.t('BY_DEVICE')]}
                            handleStatus={handleUsersStatsStatus}
                            className={classes.button}
                            variant="contained"
                            color="primary">
                        </DropDownButton>
                    </Grid>

                    <Grid container>

                    </Grid>

                </div>
                <div className={classes.mainTable}>
                    <Grid container className={classes.grid} spacing={2}>

                        {shirtsPerTeamStatus && (
                            <TeamsPerShirtsComponent date={date} />
                        )}

                        {shirtsPerCountryStatus && (
                            <CountriesPerShirtComponent date={date} />
                        )}

                        {shirtsPerStateStatus && (
                            <ShirtsPerStateComponent date={date} />
                        )}

                        {shirtsPerMuseumStatus && (
                            <ShirtsPerMuseumComponent date={date} />
                        )}

                        {userPerDeviceStatus && (
                            <UsersPerDeviceComponent date={date} />
                        )}

                    </Grid>

                </div>
            </div>
        </div >
    )
}

export default withRouter(StatsComponent);