import React from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import i18n from "../../i18n";

import ReactTableComponent from "../table/ReactTableComponent";
import OptionsComponent from "../OptionsComponent";
import SnackBarComponent from "../SnackBarComponent";

import ApiFetch from "../../services/api-fetch";

function TeamIndexComponent(props) {
  const [filters, setFilters] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [countryId, setCountryId] = React.useState("");
  const [pageNum, setPageNum] = React.useState(1);
  const [pageSize] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [snackBarMessage, setSnackBarMessage] = React.useState(null);
  const [error, setError] = React.useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: i18n.t("NAME"),
        accessor: "name",
      },
      {
        Header: i18n.t("UNIQUE_ID"),
        accessor: "unique_id",
      },
      {
        Header: i18n.t("ID"),
        accessor: "_id",
      },
      {
        id: "options",
        Header: "",
        Cell: (row, idx) => [
          <OptionsComponent
            key={idx}
            route={`teams/view/${row.row.original.unique_id}`}
            // routeEdit={`teams/edit/${row.row.original.unique_id}`}
            routeEdit={`teams/add`}
            data={row.row.original}
            isEditable={true}
          />,
        ],
      },
    ],
    []
  );

  const buildFilters = (filters) => {
    let filtered = {};
    if (!filters) {
      return Object.assign(filtered, { name: "" });
    }
    for (let i = 0; i < filters.length; i++) {
      const value = filters[i].value;
      if (filters[i].id === "name") {
        Object.assign(filtered, { name: value });
      } else if (filters[i].id === "state") {
        Object.assign(filtered, { teamState: value });
      } else if (filters[i].id === "unique_id") {
        Object.assign(filtered, { unique_id: value });
      }
    }
    return filtered;
  };

  const filtered = buildFilters(filters);

  const setPagination = (pageNum) => {
    setPageNum(pageNum);
  };

  const setErrorToFalse = () => {
    setError(false);
  };

  async function fetchCountries() {
    const url = "countries?pageSize=1000&pageNum=1&sorted[name]=1";
    const apiFetch = new ApiFetch();
    const response = await apiFetch.get(url, {
      sorted: { name: "1" },
      filtered: {},
      pageSize: 100,
      pageNum: 1,
    });
    if (!response) {
      setSnackBarMessage(i18n.t("NETWORK_ERROR"));
      setError(true);
      setCountries([]);
    } else {
      setCountries(response.countries);
      const defaultCountry = response.countries[0]._id;
      if (!countryId) {
        setCountryId(defaultCountry);
      }
    }
  }

  async function setOptionValue(value) {
    setCountryId(value);
  }

  async function fetchApi() {
    const url = `teams/country/${countryId}`;
    const apiFetch = new ApiFetch();
    if (countryId) {
      const response = await apiFetch.get(url, {
        filtered: filtered,
        sorted: { createdAt: "-1" },
        pageSize: pageSize,
        pageNum: pageNum,
      });
      if (!response) {
        setSnackBarMessage(i18n.t("NETWORK_ERROR"));
        setError(true);
        setData([]);
        setTotal(0);
      } else {
        setData(response.teams);
        setTotal(response.total);
      }
    }
  }

  useDeepCompareEffect(() => {
    fetchApi();
    fetchCountries();
  }, [data, filters, pageNum, pageSize, countryId]);

  return (
    <div>
      <ReactTableComponent
        columns={columns}
        data={data}
        filters={filters}
        setFilters={setFilters}
        setPagination={setPagination}
        onFetchData={fetchApi}
        pageNum={pageNum}
        pageSize={pageSize}
        total={{ total: total, fromDays: null }}
        path={"teams"}
        resourceName={i18n.t("TEAMS")}
        addButton={true}
        addCountryButton={true}
        filterSelectOption={{
          isDisplayed: true,
          title: i18n.t("SELECT_COUNTRY"),
          options: countries,
          setOptionValue: setOptionValue,
        }}
      />
      {error && (
        <SnackBarComponent
          snackBarMessage={snackBarMessage}
          severity={"error"}
          setErrorToFalse={setErrorToFalse}
        />
      )}
    </div>
  );
}

export default TeamIndexComponent;
