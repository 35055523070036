import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import PossibleDuplicatedComponent from './PossibleDuplicatedComponent';
import checkDuplicate from './helpers/checkDuplicate';

import i18n from '../../i18n'

import ApiFetch from '../../services/api-fetch'

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, InputLabel } from '@material-ui/core';
import { Label } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0 auto",
        width: "50%",
        fontFamily: "Roboto Condensed",
    },

    confirmButton: {
        marginTop: "8px"
    },

    title: {
        fontFamily: "Roboto Condensed",
        color: "#36425B"
    },

    duplicatedSection: {
        margin: '0 auto'
    },

    header: {
        marginBottom: '50px'
    }

}));


function CountryAddComponent(props) {

    const apiFetch = new ApiFetch()

    const classes = useStyles();
    const [country] = useState('');
    const [nameEs, setNameEs] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [countries, setCountries] = useState([]);
    const [duplicatedStatus, setDuplicatedStatus] = useState(false);
    const [error, setError] = useState(false);
    const [setSnackBarMessage] = useState(null);
    const [editStatus, setEditStatus] = useState(false);


    async function fetchCountries() {
        const url = 'countries';
        setCountries(await apiFetch.get(url, { sorted: { createdAt: '-1' }, filtered: {}, pageSize: 100, pageNum: 1 }));
    }


    function checkDuplicated() {

        const areDuplicated = checkDuplicate(nameEs, countries.countries);

        if (areDuplicated && !editStatus) {
            setDuplicatedStatus(areDuplicated);
        } else {
            create()
        };
    }

    function isDuplicatedAction() {
        props.history.push({ pathname: '/countries' })

    };

    function isNotDuplicatedAction() {
        create();
    };

    async function create() {

        const url = 'countries';
        let response = null;

        if (editStatus) {
            response = await apiFetch.update(`${url}/${props.location.data._id}`, { name: nameEs });
        } else {
            response = await apiFetch.post(url, { name: nameEs })
        }

        if (!response || response === {}) {
        } else if (response.status >= 400 && response.status <= 500) {
            setError(true)
        } else {
            props.history.push({ pathname: '/countries' })
        }
    };


    const loadExistingResource = async () => {
        if (props && props.location && props.location.data) {
            setEditStatus(true)
            setNameEs(props.location.data.name.es)
        }
    }

    useEffect(() => {
        fetchCountries();
        loadExistingResource();
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <div className={classes.header}>
                        <h4 className={classes.title}>{i18n.t('COUNTRIES')}</h4>
                        <h1 className={classes.title}>{editStatus ? i18n.t('EDIT_COUNTRY') : i18n.t('ADD_COUNTRY')}</h1>
                        <p>{i18n.t('COUNTRIES_REFERENCE')}<a href="https://es.wikipedia.org/wiki/Anexo:Pa%C3%ADses" target="_blank">{i18n.t('COUNTRY_LIST')}</a></p>
                    </div>
                    <span>
                        <InputLabel>{i18n.t('NAME_SPANISH')}</InputLabel>
                        <TextField
                            value={nameEs}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNameEs(e.target.value)}
                        />
                    </span>

                    <span>
                        <InputLabel>{i18n.t('NAME_ENGLISH')}</InputLabel>
                        <TextField
                            value={nameEn}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNameEn(e.target.value)}
                        />
                    </span>

                    <span>
                        <InputLabel>{i18n.t('ISO_CODE_ALPHA_2')}</InputLabel>
                        <TextField
                            value={nameEn}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNameEn(e.target.value)}
                        />
                    </span>

                    <span>
                        <InputLabel>{i18n.t('ISO_CODE_ALPHA_3')}</InputLabel>
                        <TextField
                            value={nameEn}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNameEn(e.target.value)}
                        />
                    </span>

                    <span>
                        <InputLabel>{i18n.t('URL_FLAG_IMAGE')}</InputLabel>
                        <TextField
                            value={nameEn}
                            error={error}
                            id="standard-full-width"
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNameEn(e.target.value)}
                        />
                    </span>

                    <div className={classes.confirmButton}>
                        <Button className={classes.buttons} size="small" variant="contained" color="primary" onClick={checkDuplicated}>{i18n.t('CONFIRM')}</Button>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.duplicatedSection}>
                        {duplicatedStatus && (
                            <PossibleDuplicatedComponent isNotDuplicatedAction={isNotDuplicatedAction} isDuplicatedAction={isDuplicatedAction} />
                        )}
                    </div>
                </Grid>
            </Grid>

        </div>

    );
}

export default withRouter(CountryAddComponent);