import environment from "../../../config/environment"
import ApiFetch from '../../../services/api-fetch';

const Resource = function () {

    this.apiFetch = new ApiFetch();

    this.getShirt = async function (url) {
        return await this.apiFetch.get(url)
    }

    this.changePusblishedStatus = async function (url, payload) {
        return await this.apiFetch.updatePut(url, payload)

    };

    this.softDelete = async function (url) {
        return await this.apiFetch.get(url)
    }
};

export default Resource;