import React from 'react'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "20px"
    },
}));

function ImageShowComponent(props) {

    const classes = useStyles();

    if (!props.data) {
        return (
            <div>Loading</div>
        )
    }

    return (
        <div className={classes.root}>
            {props.data && (
                props.data.images.map((image) => (
                    <img style={{ display: 'inline', margin: "10px" }} src={image.cloudImage} alt={image._id}></img>
                ))
            )}
        </div>
    )
}

export default withRouter(ImageShowComponent);