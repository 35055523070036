import React, { useState } from "react";
import { withRouter } from 'react-router-dom';

import i18n from "../../i18n";

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: '5px',
        // marginBottom: '15px',
    },

    buttonCheck: {
        margin: '2px'
    },

    buttonConfirm: {
        margin: '10px 2px 10px 0'
    }
}));

function PossibleDuplicatedComponent({ history, duplicated, isNotDuplicatedAction, isDuplicatedAction }) {

    const classes = useStyles();

    function checkCountries() {
        history.push({ pathname: '/countries' })

    }

    return (
        <div className={classes.root}>
            <span><Icon style={{ color: "red", fontSize: "1.5em", margin: "0 10px 0 0" }}>error</Icon></span>
            <h4>{duplicated} {i18n.t('POSSIBLE_DUPLICATED')}.</h4>
            <p>{i18n.t('POSSIBLE_DUPLICATED_NEXT_ACTION')}</p>
            <div><Button className={classes.buttonCheck} variant="outlined" size="small" color="default" onClick={checkCountries}>{i18n.t('CHECK_COUNTRIES')}</Button></div>
            <Button className={classes.buttonConfirm} variant="contained" size="small" color="secondary" onClick={() => isDuplicatedAction()}>{i18n.t('IS_DUPLICATED')}</Button>
            <Button className={classes.buttonConfirm} variant="contained" size="small" color="primary" onClick={() => isNotDuplicatedAction()}>{i18n.t('IS_NOT_DUPLICATED')}</Button>
        </div>
    )
}

export default withRouter(PossibleDuplicatedComponent);