import React, { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { withRouter, Link } from 'react-router-dom'

import { RoleType } from '../types/UserTypes';

import i18n from '../i18n'

import CountryAddComponent from '../components/country/CountryAddComponent';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';

import CheckBoxComponent from './CheckBoxComponent';

import { getJwt } from '../helpers/get-jwt'
import { getUserJwt } from '../helpers/get-user-jwt'
import { Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '0.8em'

    },
    options: {
        // textAlign: "left",
        // margin: "50px 50px 0 50px",
        marginBottom: "5px",
        padding: "1px 5px 1px 5px",
        display: 'inline-block',
    },
    optionsTotal: {
        display: "block",
        margin: "5px 0 10px 0",
    },
    optionsButton: {
        // margin: "0 10px 0 0",
        display: 'inline',
        textDecoration: 'none !important'
    },
    selectOption: {
        marginLeft: '20px',

    },
    selectContainer: {
        marginTop: '50px'
    },
    addCountryForm: {
        marginTop: '20px'
    },
    buttons: {
    }

}));

function TableOptionsComponent(props) {

    const [optionValue, setOptionValue] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [loggedInUser, setLoggedInUser] = useState({});
    const [showAddCountryForm, setShowAddCountryForm] = useState(false);
    const [addCountryFormLabel, setAddCountryFormLabel] = useState('');

    const classes = useStyles();

    const setDisabledProperty = () => {
        if (loggedInUser && loggedInUser.role === RoleType.ADMIN) {
            setDisabled(false)
        }
    }

    const handleSelect = (e) => {
        setOptionValue(e.target.value)
        props.filterSelectOption.setOptionValue(e.target.value);
    }

    const handleAddCountry = (e) => {
        if (showAddCountryForm) {
            setShowAddCountryForm(false);
            setAddCountryFormLabel(i18n.t('ADD_COUNTRY'));
        } else {
            setShowAddCountryForm(true)
            setAddCountryFormLabel(i18n.t('HIDE'))
        }
    }

    const getUser = () => {
        const jwt = getJwt()
        const user = getUserJwt(jwt)
        setLoggedInUser(user)
    }

    useDeepCompareEffect(() => {
        getUser()
        setDisabledProperty()
    }, [disabled, loggedInUser]);

    return (
        <div className={classes.root}>
            <div className={classes.options}>
                {props.opts && props.opts.type === 'checkbox' && (
                    <CheckBoxComponent opts={props.opts} />
                )}
                {showAddCountryForm && (
                    <div className={classes.addCountryForm}>
                        {props.addCountryButton && loggedInUser.role === RoleType.ADMIN && (
                            <CountryAddComponent />
                        )}
                    </div>
                )}

                {props.filterSelectOption && props.filterSelectOption.title && (
                    <div>
                        <span className={classes.selectContainer}>
                            {props.filterSelectOption.title}
                            <Select
                                className={classes.selectOption}
                                onChange={handleSelect}
                                value={optionValue}
                            >
                                <MenuItem value={""} disabled>
                                    {props.filterSelectOption.title}
                                </MenuItem>
                                {props.filterSelectOption.options.map((option, idx) => {
                                    return (
                                        <MenuItem key={idx} value={option._id}>{option.name.es}</MenuItem>
                                    )
                                })}
                            </Select>
                        </span>
                    </div>

                )}
            </div>
            <div className={classes.options}>
                {props.addButton && loggedInUser.role === RoleType.ADMIN && (
                    <span className={classes.optionsButton}><Link to={props.path}><Button size="small" variant="outlined">{i18n.t('ADD_ITEM')}</Button></Link></span>
                )}
                {props.addCountryButton && (
                    <span>
                        <Button className={classes.buttons} size="small" variant="outlined" onClick={handleAddCountry}>{addCountryFormLabel ? addCountryFormLabel : i18n.t('ADD_COUNTRY')}</Button>
                    </span>
                )}
            </div>
        </div>
    )
}

export default withRouter(TableOptionsComponent);