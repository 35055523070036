import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect'
import i18n from '../../i18n';

import ApiFetch from '../../services/api-fetch'
import getRelativePath from '../../helpers/getRelativePath'

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SecurityIcon from '@material-ui/icons/Security';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
    root: {
        width: "70%",
        margin: "0 auto",
        fontFamily: "Roboto Condensed",
    },
    text: {
        fontSize: '0.9em',
        padding: '2px'
    },
    input: {
        width: "200px"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    titleName: {
        textDecoration: 'underline',
        color: "#36425B",
    },
    section: {
        margin: "0 5px"
    }
    
}));


export default function VinimayTeamEditComponent(props) {
    const apiFetch = new ApiFetch()
    const userId = getRelativePath(props.location.pathname)

    const classes = useStyles();
    const [role, setRole] = useState(null)
    const [label, setLabel] = useState(null)
    const [data, setData] = useState({})
    const [editStatus, setEditStatus] = useState(false)
    const [confirmStatus, setConfirmStatus] = useState(false)

    async function fetchUser() {
        const url = `/users/${userId}`
        const response = await apiFetch.get(url)
        if (response) {
            setData(response)
        }
    }

    const updateRole = async () => {
        const url = `/users/${userId}`
        const payload = {
            role: role
        }
        await apiFetch.updatePut(url, payload)
        props.history.push('/vinimay-team')
    }

    const handleRoleChange = (e) => {
        setRole(e.target.value)
        switch (e.target.value) {
            case 1:
                setLabel(i18n.t('ADMIN'));
                break;
            case 2:
                setLabel(i18n.t('EMPLOYEE'));
                break;
            case 0:
                setLabel(i18n.t('USER'));
                break;
            default:
                setLabel('');
        }
        setConfirmStatus(true)
    }

    const parseRole = (role) => {
        switch (role) {
            case 1:
                return i18n.t('ADMIN');
                break;

            case 2:
                return i18n.t('EMPLOYEE');
                break;
        }
    };
    useDeepCompareEffect(() => {
        fetchUser()
    }, [data]);

    return (
        <Grid className={classes.root} container>
            <Grid item xs={6} >
            <h1 className={classes.titleName}>{`${data.firstName} ${data.lastName}`}</h1>
                <div>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <PermIdentityIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18n.t('NAME')} secondary={data ? data.firstName : ""} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <AlternateEmailIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18n.t('EMAIL')} secondary={data ? data.email : ""} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <SecurityIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={i18n.t('PERMISSION_GROUP')} secondary={data ? parseRole(data.role) : ""} />
                            <IconButton onClick={() => setEditStatus(true)} aria-label="delete" label="asdasd">
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </ListItem>
                    </List>
                </div>
            </Grid>
            {editStatus && (
                <Grid item xs={5}>
                    <p class={classes.text}>{i18n.t('SELECT_ROLE_WARNING')}</p>
                    <FormControl variant="outlined" className={classes.input}>
                        <InputLabel class={classes.input} id="role">{i18n.t('ROLE')}</InputLabel>
                        <Select
                            className={classes.input}
                            value={role}
                            onChange={handleRoleChange}
                        >
                            <MenuItem value={""} disabled>
                                <em>{i18n.t('SELECT')}</em>
                            </MenuItem>
                            <MenuItem value={1}>{i18n.t('ADMIN')}</MenuItem>
                            <MenuItem value={2}>{i18n.t('EMPLOYEE')}</MenuItem>
                            <MenuItem value={0}>{i18n.t('USER')}</MenuItem>
                        </Select>
                    </FormControl>
                    {confirmStatus && (
                        <div>
                            <p class={classes.text}>{i18n.t('CONFIRM_CHANGE_ROLE')}<strong>{data.firstName}</strong> a <strong>{label}</strong> ?</p>
                            <Button color="primary" onClick={updateRole}>{i18n.t('CONFIRM')}</Button>
                            <Button color="secondary" onClick={() => setEditStatus(false)}>{i18n.t('CANCEL')}</Button>
                        </div>

                    )}
                </Grid>
            )}

        </Grid >
    );
}
