import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Link } from 'react-router-dom'
import moment from 'moment';

import Resource from "./resource/Resource";
import urls from "./resource/urls";

import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../i18n';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        margin: "0 auto",
        fontFamily: "Roboto Condensed",
    },
    gridList: {
        width: 700,
        height: 700,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },

    title: {
        color: "#36425B",
        width: "100%",
        marginBottom: "20px",
        textDecoration:"underline",
    },

    image: {
        maxWidth: "100%",
        maxHeight: "100%",
    },

    shirtInfo: {
        textAlign: "center"
    }


}));

export default function TitlebarGridList() {

    const classes = useStyles();
    const [data, setData] = React.useState({})

    const resource = new Resource();


    async function fetchApi() {
        const response = await resource.getImages(urls.getImages);
        !response ? setData([]) : setData(response)
    }

    useDeepCompareEffect(() => {
        fetchApi()
    }, [data]);

    console.log(data.images)

    return (
        <div>
            <Grid container className={classes.root}>
            <span className={classes.title}>
                <h1>{i18n.t('IMAGES')}</h1>
                </span>                    
                {data && data.images && (
                    data.images.map((image) => (
                        image.images.map(i => {
                            return (
                                <div style={{ border: "solid 2px #36425B", padding: "1px", margin: "2px" }}>
                                    <Grid className={classes.shirtInfo} container>
                                        <Grid item xs={6}>
                                            <img className={classes.image} src={i.cloudImage} alt={i.imageName} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p>{i18n.t('DATE_REGISTERED')} - <strong>{moment(i.createdAt).format('DD-MM-YYYY')}</strong></p>
                                            <p>{i18n.t('USERNAME')} - <strong>{image.shirtUser.username}</strong></p>
                                            <p>{<Link path to={`shirts/view/${image._id}`}>Ver camiseta</Link>}</p>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })
                    ))
                )}
            </Grid>
        </div>

    );
}
