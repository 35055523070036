const styles = {
    root: {
        position: "relative",
        margin: "2px",
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        overflow: "auto"
    },

    main: {
        position: "absolute",
        width: "80%",
        height: "80%",
        margin: "50px",
    },

    button: {
        margin: "10px",
    },

    mainTable: {
        margin: "30px",

    },

    grid: {
        flexGrow: 1,
    },

    title: {
        textAlign: "left",
        fontFamily: "Roboto Condensed",
        color: "#36425B"
    },

    tableRoot: {
        width: "350px",
        height: "400px",
        margin: "15px"
    },
    table: {
        backgroundColor: "#f6f5f5",
    },

    rows: {
        fontSize: "0.8em",
    },

    mainCells: {
        fontWeight: "bold",
    },

    cells: {
        fontSize: "0.8em",
    },

    calendar_label: {
        fontFamily: "Roboto Condensed",
        fontSize: "0.8em",
        margin: "0 5px 0 0",
    }
}

export default styles;