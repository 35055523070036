import React, { useState, useEffect } from "react";

import Resource from './resources/Resource';

import urls from "./resources/urls";

import useStyles from "./styles/useStyles";

import i18n from '../../i18n';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


export default function ShirtsPerMuseumComponent({ date }) {

    const resource = new Resource();

    const classes = useStyles();

    const [shirtsPerMuseum, setShirtsPerMuseum] = useState([]);

    async function fetchData() {
        const response = await resource.getShirtsPerMusuem(urls.getShirtsPerMuseum, date);

        if (!response) {
            console.log("Error")
        }
        setShirtsPerMuseum(response.shirtsPerMuseum);
    }


    useEffect(() => {
        fetchData()
    }, []);


    return (
        <TableContainer className={classes.tableRoot} component={Paper}>
            {shirtsPerMuseum && shirtsPerMuseum.length > 0 && (
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.mainCells}>{i18n.t('USERS')}</TableCell>
                            <TableCell className={classes.mainCells}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shirtsPerMuseum.map((s) => (
                            <TableRow key={s._id}>
                                <TableCell component="th" scope="row">
                                    {s.username}
                                </TableCell>
                                <TableCell align="center">{s.shirts}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}

        </TableContainer>
    );
}