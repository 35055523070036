const checkDuplicates = (data, countries) => {

    const loweredCasedCountryNames = countries.map(c => c.name.toLowerCase());

    const exactlyEquals = loweredCasedCountryNames.filter(l => {
        return l === data.toLowerCase();
    });

    if (exactlyEquals && exactlyEquals.length) {
        return true;
    }

    else {
        const dataSplitted = data.split(" ");
        for (let i = 0; i < loweredCasedCountryNames.length; i++) {

            const loweredSplitted = loweredCasedCountryNames[i].split(" ");

            for (let j = 0; j < dataSplitted.length; j++) {
                if (loweredSplitted.includes(dataSplitted[j].toLowerCase())) {
                    return true;
                }
            }
        };
    };

    return false
};

export default checkDuplicates;