const styles = {
  root: {},

  main: {
    position: "absolute",

    margin: "0 50px 0 50px",
  },

  title: {
    textAlign: "left",
    fontFamily: "Roboto Condensed",
    color: "#36425B",
  },

  card: {
    minWidth: 275,
    display: "inline-block",
    margin: "10px",
    flexGrow: 1,
  },

  cardContent: {
    backgroundColor: "#f6f5f5",
  },

  cardTitle: {
    fontSize: 14,
  },

  cardInfo: {
    textAlign: "left",
  },

  cardPos: {
    marginBottom: 12,
  },

  gridContainer: {
    flexGrow: 1,
  },

  viewNewHeader: {
    backgroundColor: "#36425B",
    fontFamily: "Roboto Condensed",
    color: "white",
    textAlign: "left",
    padding: "5px",
  },

  viewNewIdentifier: {
    textAlign: "left",
    padding: "5px",
    fontStyle: "italic",
    fontSize: "1.1em",
  },

  date: {
    backgroundColor: "#36425B",
    color: "white",
    marginBottom: "50px",
  },
};

export default styles;
