import React, { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { withRouter, Link } from 'react-router-dom'

import { RoleType } from '../types/UserTypes';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import { getJwt } from '../helpers/get-jwt';
import { getUserJwt } from '../helpers/get-user-jwt';

const useStyles = makeStyles((theme) => ({
    linkSee: {
        textDecoration: "grey",
        color: "grey"
    },
    linkEdit: {
        textDecoration: "grey",
        color: "grey"
    },
    linkDelete: {
        textDecoration: "grey",
        color: "grey"
    },
}));

function OptionsComponent(props) {

    const [disabled, setDisabled] = useState(true)
    const [loggedInUser, setLoggedInUser] = useState({})
    const classes = useStyles();

    const setDisabledProperty = () => {
        if (loggedInUser && loggedInUser.role === RoleType.ADMIN) {
            setDisabled(false)
        }
    }

    const getUser = () => {
        const jwt = getJwt()
        const user = getUserJwt(jwt)
        setLoggedInUser(user)
    }

    useDeepCompareEffect(() => {
        getUser()
        setDisabledProperty()
    }, [disabled, loggedInUser]);

    return (
        <div>
            <div>
                {props.canSeeDetail && (
                    <IconButton aria-label="delete" disabled={false}>
                        <Link className={classes.linkSee} to={{ pathname: props.route, data: props.data }}><Icon className="option-link" fontSize="small">visibility</Icon></Link>
                    </IconButton>
                )}
                {props.isEditable && loggedInUser.role === RoleType.ADMIN && (
                    <IconButton aria-label="delete" disabled={disabled}>
                        <Link className={classes.linkEdit} to={{ pathname: props.routeEdit, data: props.data }}> <Icon fontSize="small">edit</Icon></Link>
                    </IconButton>
                )}
                {props.handleDeleteButton && loggedInUser.role === RoleType.ADMIN && (
                    <IconButton className={classes.linkDelete} onClick={() => props.handleDeleteButton(props.data)} aria-label="delete" disabled={disabled}>
                        <DeleteForeverOutlinedIcon fontSize="small" />
                    </IconButton>
                )}
            </div >
        </div>
    )
}

export default withRouter(OptionsComponent);