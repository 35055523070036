import React, { useState } from "react";
import useDeepCompareEffect from 'use-deep-compare-effect'

import useStyles from "./styles/useStyles";

import MinifiedReactTableComponent from "../table/MinifiedReactTableComponent";
import columns from "./table-colunms/activityComponentColumns";
import i18n from "../../i18n";

function ViewNewComponent(props) {

    console.log(columns)
    const [columnsTable, setColumnsTable] = useState([]);

    const classes = useStyles();

    function selectColumns() {
        setColumnsTable(columns)
    };

    useDeepCompareEffect(() => {
        selectColumns()
    }, [columnsTable]);


    return (
        <div >
            {props && props.location && props.location.state && (
                <MinifiedReactTableComponent
                    data={props.location.state.data}
                    columns={columnsTable}
                />
            )}

        </div>
    )
}

export default ViewNewComponent;