import React, { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import moment from "moment";

import i18n from "../../i18n";

import urls from "./resources/urls";
import Resource from "./resources/Resource";

import getRelativePath from "../../helpers/getRelativePath";

import { AuthType, DeviceType } from "../../types/UserTypes";

import UserMuseumComponent from "./UserMuseumComponent";
import SnackBarComponent from "../SnackBarComponent";
import AlertModalComponent from "../modals/AlertModalComponent";
import DropDownButton from "../DropDownButton";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import FaceIcon from "@material-ui/icons/Face";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import WcIcon from "@material-ui/icons/Wc";
import RoomIcon from "@material-ui/icons/Room";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import HomeIcon from "@material-ui/icons/Home";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import BlockIcon from "@material-ui/icons/Block";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from "@material-ui/icons/Android";
import HelpIcon from "@material-ui/icons/Help";
import PhonelinkLockIcon from "@material-ui/icons/PhonelinkLock";
import FingerprintIcon from "@material-ui/icons/Fingerprint";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "70%",
    margin: "0 auto",
    fontFamily: "Roboto Condensed",
  },
  root: {
    margin: "20px 0 0 50px",
    width: "100%",
    maxWidth: 250,
    backgroundColor: "theme.palette.background.paper",
  },

  green: {
    backgroundColor: "green",
  },

  orange: {
    backgroundColor: "orange",
  },

  red: {
    backgroundColor: "red",
  },

  titleName: {
    // textDecoration: 'underline',
  },

  statusAcountButtom: {
    display: "inline-block !important",
  },

  verifiedAccountStatus: {
    border: "solid 2px green",
    padding: "1px",
    textAlign: "center",
    color: "green",
  },

  unverifiedAccountStatus: {
    border: "solid 2px orange",
    padding: "1px",
    textAlign: "center",
    color: "orange",
  },

  bannedAccountStatus: {
    border: "solid 2px red",
    padding: "1px",
    textAlign: "center",
    color: "red",
  },
}));

export default function UserDetailViewComponent(props) {
  const resource = new Resource();

  const classes = useStyles();

  const [data, setData] = useState({});
  const [userMuseum, setUserMuseum] = useState({});
  const [userMuseumStatus, setUserMuseumStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(null);
  const [generatePasswordStatus, setGeneratePasswordStatus] = useState(false);

  const relativePath = getRelativePath(props.location.pathname);

  async function fetchApi() {
    const response = await resource.getUser(
      `${urls.root}/get-complete/${relativePath}`
    );
    if (!response) setData({});
    else setData(response);
  }

  async function getUserMuseum() {
    setUserMuseum(
      await resource.getUserMuseum(`${urls.getUserMuseum}/${relativePath}`)
    );
  }

  async function handleStatus(resource) {
    switch (resource) {
      case i18n.t("VERIFY"):
        changeAccountStatus(1);
        break;

      case i18n.t("UNVERIFY"):
        changeAccountStatus(0);
        break;

      case i18n.t("BAN"):
        changeAccountStatus(2);
        break;
    }
  }

  async function changeAccountStatus(statusValue) {
    const payload = { status: statusValue };
    const response = await resource.changeAccountStatus(
      `${urls.changeAccountStatus}/${relativePath}`,
      payload
    );
    const res = response.response;
    if (!response) setSnackBarMessage(i18n.t("NETWORK_ERROR"));
    else if (res.status >= 400 && res.status <= 500) {
      setSnackBarMessage(`${res.message} (${res.status})`);
      setError(true);
    } else {
      setSnackBarMessage(i18n.t("SUCCESS_CHANGE_STATUS"));
      setSuccess(true);
      fetchApi();
    }
  }

  async function changeUserVerified() {
    const payload = { isVerified: !data.isVerified };
    const response = await resource.changeUserVerified(
      `${urls.root}/${relativePath}`,
      payload
    );
    const res = response.response;
    if (!res) setSnackBarMessage(i18n.t("NETWORK_ERROR"));
    else if (res.status >= 400 && res.status <= 500) {
      setSnackBarMessage(`${res.message} (${res.status})`);
      setError(true);
    } else {
      setSnackBarMessage(i18n.t("SUCCESS_VERIFIED_STATUS"));
      setSuccess(true);
      fetchApi();
    }
  }

  const handleDeleteButton = (value) => {
    setModal(true);
  };

  const handleConfirmDelete = async () => {
    const response = await resource.softDelete(
      `${urls.softDelete}/${relativePath}`
    );
    if (!response || (response.status >= 400 && response.status <= 500)) {
      setModal(false);
      setSnackBarMessage(`${response.message} (${response.status})`);
      setError(true);
    } else {
      setModal(false);
      props.history.push({
        pathname: "/users",
        state: {
          snackBar: true,
          snackBarMessage: i18n.t("USER_SUCCESSFULLY_DELETED"),
        },
      });
    }
  };

  const handleConfirmGeneratePassowrd = async () => {
    const response = await resource.generatePassword(
      `${urls.generatePassword}/${relativePath}`
    );
    if (!response || (response.status >= 400 && response.status <= 500)) {
      setModal(false);
      setSnackBarMessage(`${response.message} (${response.status})`);
      setError(true);
    } else {
      setModal(false);
      props.history.push({
        pathname: "/users",
        state: {
          snackBar: true,
          snackBarMessage: i18n.t("PASSWORD_SUCCESSFULLY_GENERATED"),
        },
      });
    }
  };

  const parseAuthType = (authType) => {
    switch (authType) {
      case AuthType.EMAIL:
        return i18n.t("EMAIL");
        break;
      case AuthType.FACEBOOK:
        return i18n.t("FACEBOOK");
        break;
      case AuthType.GOOGLE:
        return i18n.t("GOOGLE");
        break;
      case AuthType.APPLE:
        return i18n.t("APPLE");
        break;
    }
  };

  const handleMuseumStatus = () => {
    setUserMuseumStatus(!userMuseumStatus);
  };

  const handleGeneratePasswordButton = () => {
    setGeneratePasswordStatus(true);
  };

  const handleChangeUserVerified = () => {
    changeUserVerified();
  };

  const setErrorToFalse = () => {
    setError(false);
  };

  const setSuccessToFalse = () => {
    setSuccess(false);
  };

  useDeepCompareEffect(() => {
    fetchApi();
    getUserMuseum();
  }, [data]);

  console.log("DATA");

  return (
    <div>
      {error && (
        <SnackBarComponent
          snackBarMessage={snackBarMessage}
          severity={"error"}
          setErrorToFalse={setErrorToFalse}
        />
      )}
      {success && (
        <SnackBarComponent
          snackBarMessage={snackBarMessage}
          severity={"success"}
          setSuccessToFalse={setSuccessToFalse}
        />
      )}
      {modal && (
        <AlertModalComponent
          item={data}
          cancel={() => setModal(false)}
          handleConfirm={handleConfirmDelete}
          infoMessage={i18n.t("DELETING_THIS_USER_WILL_CAUSE_ALL_DATA_LOOSING")}
          warningMessage={i18n.t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER")}
        />
      )}

      {generatePasswordStatus && (
        <AlertModalComponent
          item={data}
          cancel={() => setModal(false)}
          handleConfirm={handleConfirmGeneratePassowrd}
          infoMessage={i18n.t("PASSWORD_WILL_BE_REPLACED_MESSAGE")}
          warningMessage={i18n.t("PASSWORD_WILL_NOT_BE_SHOWN_MESSAGE")}
        />
      )}

      <Grid container className={classes.main}>
        <Grid item xs={6}>
          <List className={classes.root}>
            <span>
              <h1 className={classes.titleName}>
                {" "}
                {data.device === DeviceType.ANDROID ? (
                  <AndroidIcon />
                ) : DeviceType.APPLE ? (
                  <AppleIcon />
                ) : (
                  <HelpIcon />
                )}{" "}
                {`${data.firstName} ${data.lastName}`}
              </h1>
            </span>
            <p
              className={
                data.status === 0
                  ? classes.unverifiedAccountStatus
                  : data.status === 1
                  ? classes.verifiedAccountStatus
                  : classes.bannedAccountStatus
              }
            >
              {data.status === 0
                ? i18n.t("UNVERIFIED_ACCOUNT")
                : data.status === 1
                ? i18n.t("VERIFIED_ACCOUNT")
                : i18n.t("BANNED_ACCOUNT")}
            </p>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CalendarTodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("DATE_REGISTERED")}
                secondary={moment(data.createdAt).format("DD-MM-YYYY")}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <FaceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("USERNAME")}
                secondary={data.username}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AlternateEmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={i18n.t("EMAIL")} secondary={data.email} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <FingerprintIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("ID_NUMBER")}
                secondary={
                  data.idNumber ? data.idNumber : i18n.t("NOT_SPECIFIED")
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AlternateEmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("AUTH_TYPE")}
                secondary={parseAuthType(data.authType)}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SmartphoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("PHONE_NUMBER")}
                secondary={data.phone}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WcIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("GENDER")}
                secondary={data.gender ? data.gender : i18n.t("NOT_SPECIFIED")}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <RoomIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("COUNTRY")}
                secondary={data.address ? data.address.country.displayName : ""}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <LocationCityIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("CITY")}
                secondary={data.address ? data.address.city : ""}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <HomeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={i18n.t("ADDRESS")}
                secondary={
                  data.address && data.address.street
                    ? `${data.address.street} ${data.address.streetNumber} ${
                        data.address.zipCode ? data.address.zipCode : ""
                      }`
                    : i18n.t("NOT_SPECIFIED")
                }
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <HomeIcon />
                </Avatar>
              </ListItemAvatar>
              <Button color="primary" onClick={handleMuseumStatus}>
                {i18n.t("SEE_MUSEUM")}{" "}
                {`(${
                  userMuseum.userMuseum
                    ? userMuseum.userMuseum.museum.shirts.length
                    : 0
                })`}
              </Button>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <List className={classes.root}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={data.isVerified ? classes.green : ""}>
                  <VerifiedUserIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  data.isVerified
                    ? i18n.t("USER_VERIFIED")
                    : i18n.t("USER_NOT_VERIFIED")
                }
                secondary={
                  <Button onClick={handleChangeUserVerified}>
                    {data.isVerified ? i18n.t("DESACTIVE") : i18n.t("VERIFY")}
                  </Button>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.red}>
                  <DeleteOutlineIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <Button onClick={handleDeleteButton}>
                    {i18n.t("DELETE")}
                  </Button>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  {data.status === 1 ? (
                    <PhonelinkLockIcon />
                  ) : data.status === 0 ? (
                    <ErrorOutlineIcon />
                  ) : (
                    <BlockIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <div className={classes.statusAcountButtom}>
                    <ListItemText
                      secondary={
                        <Button onClick={handleGeneratePasswordButton}>
                          {i18n.t("GENERATE_PASSWORD")}
                        </Button>
                      }
                    />
                  </div>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar
                  className={
                    data.status === 1
                      ? classes.green
                      : data.status === 0
                      ? classes.orange
                      : classes.red
                  }
                >
                  {data.status === 1 ? (
                    <VerifiedUserIcon />
                  ) : data.status === 0 ? (
                    <ErrorOutlineIcon />
                  ) : (
                    <BlockIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <div className={classes.statusAcountButtom}>
                    <DropDownButton
                      resource={i18n.t("ACCOUNT_STATE")}
                      options={[
                        i18n.t("VERIFY"),
                        i18n.t("UNVERIFY"),
                        i18n.t("BAN"),
                      ]}
                      handleStatus={handleStatus}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    ></DropDownButton>
                  </div>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      {userMuseum && userMuseumStatus && (
        <UserMuseumComponent userMuseum={userMuseum.userMuseum} />
      )}
    </div>
  );
}
