import React from 'react'
import { withRouter } from 'react-router-dom'

function LoginComponent(props) {

    if (!props.loggedInUser) {
        return props.history.push('/login')
    }

    return (
        <div>
            {props.children}
        </div>
    )
}

export default withRouter(LoginComponent);