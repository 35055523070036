const styles = {
  main: {
    width: "70%",
    margin: "0 auto",
    fontFamily: "Roboto Condensed",
  },

  detailViewRoot: {
    margin: "20px 0 0 50px",
    width: "100%",
    maxWidth: 360,
  },

  detailViewIcon: {
    color: "rgba(255, 255, 255, 0.54)",
  },

  publishedStatus: {
    border: "solid 2px green",
    padding: "1px",
    textAlign: "center",
    color: "green",
  },

  unPublishedStatus: {
    border: "solid 2px red",
    padding: "1px",
    textAlign: "center",
    color: "red",
  },

  red: {
    backgroundColor: "red",
  },

  green: {
    backgroundColor: "green",
  },

  statusType: {
    color: "white",
    textTransform: "uppercase",
    backgroundColor: "grey",
    padding: "1px",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "0.9em",
  },
};

export default styles;
