import React, { useState } from "react";

import SnackBarComponent from "../SnackBarComponent";

import i18n from "../../i18n";

import ApiFetch from "../../services/api-fetch";

import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "50%",
    fontFamily: "Roboto Condensed",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inputCountry: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(4),
    width: "100%",
  },
  confirmScreen: {
    marginTop: "40px",
    fontFamily: "Roboto Condensed",
    margin: "0 auto",
  },
  buttons: {
    margin: "2px",
  },
  continueButtom: {
    marginTop: "5%",
  },
  title: {
    fontFamily: "Roboto Condensed",
    color: "#36425B",
  },
  header: {
    marginBottom: "60px",
  },

  createdResult: {
    backgroundColor: "#36425B",
    color: "white",
    width: "80%",
    padding: "0 0 0 2px",
  },
}));

export default function TeamAddComponent(props) {
  const apiFetch = new ApiFetch();

  const classes = useStyles();
  const [country, setCountry] = React.useState("");
  const [editStatus, setEditStatus] = React.useState(false);
  const [selectedCountryName, setSelectedCountryName] = React.useState("");
  const [name, setName] = React.useState("");
  const [continueStatus, setContinueStatus] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [error, setError] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState(null);

  const [countriesList, setCountriesList] = React.useState([]);

  async function loadCountries() {
    const url = "countries?pageSize=1000&pageNum=1&sorted[name]=1";
    const response = await apiFetch.get(url);
    if (!response || response === {}) {
      return setSnackBarMessage(i18n.t("NETWORK_ERROR"));
    } else if (response.status >= 400 && response.status <= 500) {
      setSnackBarMessage(`${response.message} (${response.status})`);
      setError(true);
    } else {
      setCountriesList(response.countries);
    }
  }

  async function create() {
    const url = "teams";
    let response = null;
    if (editStatus) {
      response = await apiFetch.update(`${url}/${props.location.data._id}`, {
        name: name,
        country: country,
      });
    } else {
      response = await apiFetch.post(url, { name: name, country: country });
    }

    if (!response || response === {}) {
      return setSnackBarMessage(i18n.t("NETWORK_ERROR"));
    } else if (response.status >= 400 && response.status <= 500) {
      setSnackBarMessage(`${response.message} (${response.status})`);
      setError(true);
    } else {
      props.history.push({ pathname: "/teams" });
    }
  }

  const fetchCountry = async (value) => {
    return await apiFetch.get(`countries/${value}`);
  };

  const handleCountryChange = async (e) => {
    const response = await fetchCountry(e.target.value);
    setSelectedCountryName(response.country.name.es);
    setCountry(e.target.value);
  };

  const handleContinueStatus = async () => {
    if (country && name) {
      setError(false);
      setDisabled(true);
      setContinueStatus(true);
    } else {
      setError(true);
    }
  };

  const handleCancelButton = () => {
    setDisabled(false);
    setContinueStatus(false);
  };

  const loadExistingResource = async () => {
    if (props && props.location && props.location.data) {
      setEditStatus(true);
      setName(props.location.data.name);
      setCountry(props.location.data.country);
      const c = await fetchCountry(props.location.data.country);
      setSelectedCountryName(c.country.name.es);
    }
  };

  React.useEffect(() => {
    loadCountries();
    loadExistingResource();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <div className={classes.root}>
          <div className={classes.header}>
            <h4 className={classes.title}>{i18n.t("TEAMS")}</h4>
            <h1 className={classes.title}>
              {editStatus ? i18n.t("EDIT_TEAM") : i18n.t("CREATE_NEW_TEAM")}
            </h1>
          </div>
          <div>
            <InputLabel id="role">{i18n.t("SELECT_COUNTRY")}</InputLabel>
            <Select
              className={classes.inputCountry}
              labelId="pais"
              id="pais"
              onChange={handleCountryChange}
              value={country}
            >
              <MenuItem value={""} disabled>
                {i18n.t("SELECT_COUNTRY")}
              </MenuItem>
              {countriesList.map((country, idx) => {
                return (
                  <MenuItem key={idx} value={country._id}>
                    {country.name.es}
                  </MenuItem>
                );
              })}
            </Select>
            <InputLabel id="role">{i18n.t("WRITE_TEAM_NAME")}</InputLabel>
            <TextField
              value={name}
              disabled={disabled}
              error={error}
              id="standard-full-width"
              style={{ margin: 4 }}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={classes.continueButtom}>
            {name && country && (
              <Button
                disabled={disabled}
                onClick={handleContinueStatus}
                size="small"
                variant="contained"
                color="primary"
                className={classes.continueButtom}
              >
                {i18n.t("CONTINUE")}
              </Button>
            )}
          </div>
        </div>
      </Grid>
      {continueStatus && (
        <Grid className={classes.confirmScreen} item xs={6}>
          <div>
            <label>{i18n.t("TEAM")}</label>
            <p className={classes.createdResult}>
              <strong>{name}</strong>
            </p>
          </div>
          <div>
            <label className={classes.confirmReference}>
              {i18n.t("COUNTRY")}
            </label>
            <p className={classes.createdResult}>
              <strong>{selectedCountryName}</strong>
            </p>
          </div>
          <div>
            <Button
              className={classes.buttons}
              size="small"
              variant="contained"
              color="primary"
              onClick={create}
            >
              {i18n.t("CONFIRM")}
            </Button>
            <Button
              className={classes.buttons}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleCancelButton}
            >
              {i18n.t("CANCEL")}
            </Button>
          </div>
        </Grid>
      )}
      {error && (
        <SnackBarComponent
          snackBarMessage={snackBarMessage}
          severity={"error"}
        />
      )}
    </Grid>
  );
}
