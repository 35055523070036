import React, { useEffect, useState } from "react";
import Markdown from 'react-markdown';
import i18n from '../i18n';
import Changelog from '../Changelog';
import MainHeaderComponent from './MainHeaderComponent';


function ChangelogComponent() {

    const styles = {
        root: {
            margin: '15px'
        },

        // content: {
        //     textAlign: "justify",
        //     backgroundColor: "#f6f5f5",
        //     padding: '5px',
        //     position: "absolute",
        //     width: "85%",
        //     height: "85%",
        //     margin: "50px",
        //     overflow: "auto"
        // },

        // _body: {
        //     backgroundColor: 'white',
        //     padding: '10px'
        // }
    }

    const md = Changelog;

    return (
        <div style={styles.root}>
            <div style={styles.content}>
                <div style={styles._body}>
                    <Markdown source={md} />
                </div>
            </div>

        </div>
    )
}

export default ChangelogComponent;